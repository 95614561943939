export const initialData = [
  {
    key: "tyres",
    name: "Tyres",
    setup1: "-",
    setup2: "-",
    children: [
      {
        key: "tyreCompound",
        name: "tyre",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "tyrePressure",
        name: "psi (°C)",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "toe",
        name: "toe (°)",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "camber",
        name: "camber (°)",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "caster",
        name: "caster (°)",
        setup1: [0, 0],
        setup2: [0, 0],
      },
    ],
  },
  {
    key: "electronics",
    name: "Electronics",
    setup1: "-",
    setup2: "-",
    children: [
      {
        key: "tC1",
        name: "TC1",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "tC2",
        name: "TC2",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "abs",
        name: "ABS",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "ecuMap",
        name: "ECUMap",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "telemetryLaps",
        name: "telemetry laps",
        setup1: 0,
        setup2: 0,
      },
    ],
  },
  {
    key: "fuelandstrategy",
    name: "Fuel & Strategy",
    setup1: "-",
    setup2: "-",
    children: [
      {
        key: "fuel",
        name: "fuel",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "tyreSet",
        name: "tyre set",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "frontBrakePadCompound",
        name: "front brakes",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "rearBrakePadCompound",
        name: "rear brakes",
        setup1: 0,
        setup2: 0,
      },
    ],
  },
  {
    key: "mechanical",
    name: "Mechanical Grip",
    setup1: "-",
    setup2: "-",
    children: [
      {
        key: "aRB",
        name: "antiroll bar",
        setup1: [0, 0],
        setup2: [0, 0],
      },
      {
        key: "brakeTorque",
        name: "brake power (%)",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "brakeBias",
        name: "brake bias (%)",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "steerRatio",
        name: "steer ratio (%)",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "wheelRate",
        name: "wheel rate (Nm)",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },

      {
        key: "bumpStopRateUp",
        name: "bumpstop rate (N)",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      // {
      //   key: "bumpStopRateDn",
      //   name: "BumpStopRateDn (N)",
      //   setup1: [0, 0, 0, 0],
      //   setup2: [0, 0, 0, 0],
      // },
      {
        key: "bumpStopWindow",
        name: "bumpstop range",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "preload",
        name: "preload diff (Nm)",
        setup1: 0,
        setup2: 0,
      },
    ],
  },
  {
    key: "dampers",
    name: "Dampers",
    setup1: "-",
    setup2: "-",
    children: [
      {
        key: "bumpSlow",
        name: "bump",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "bumpFast",
        name: "fast bump",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "reboundSlow",
        name: "rebound",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
      {
        key: "reboundFast",
        name: "fast rebound",
        setup1: [0, 0, 0, 0],
        setup2: [0, 0, 0, 0],
      },
    ],
  },
  {
    key: "aeroBalance",
    name: "Aero",
    setup1: "-",
    setup2: "-",
    children: [
      {
        key: "rideHeight",
        name: "ride height",
        setup1: [0, 0],
        setup2: [0, 0],
      },
      {
        key: "rearWing",
        name: "rear wing",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "splitter",
        name: "splitter",
        setup1: 0,
        setup2: 0,
      },
      {
        key: "brakeDuct",
        name: "brake ducts",
        setup1: [0, 0],
        setup2: [0, 0],
      },
    ],
  },
];
