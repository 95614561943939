import React from "react";
import { Card, Row, Col } from "antd";

const Insights = () => {
  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginTop: 16,
          marginBottom: 16,
          marginLeft: 12,
          marginRight: 12,
        }}
      >
        <Col xs={24} sm={24} xl={24}>
          <Card
            title={"Accsetupcomparator Insights"}
            className="innerpage-card"
            bordered={true}
            style={{
              minHeight: "50vh",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            }}
          >
            <Card
              type="inner"
              title="Google analytics report"
              style={{ marginTop: 8 }}
            >
              <iframe
                title="acc_insights"
                width="100%"
                height="400px"
                src="https://datastudio.google.com/embed/reporting/b52365fc-9370-4dad-baf5-33999f6d566e/page/kpoBD"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
            </Card>
            <Card type="inner" title="Contact" style={{ marginTop: 8 }}>
              <div
                style={{
                  marginBottom: 16,
                }}
              >
                For business related queries contact me rajavn6789@gmail.com or
                Raja#0377 in discord
              </div>
            </Card>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Insights;
