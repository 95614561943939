import React from "react";
import { Table, Card } from "antd";
import Chasisicon from "../../../assets/Chasisicon";

const Aero = ({ data, mode, isSetup2Loaded }) => {
  // 1. Front data

  const frontKeys = ["rideHeight", "splitter", "brakeDuct"];
  const frontDataSource = data.children.filter((item) =>
    frontKeys.includes(item.key)
  );

  const frontColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 80,
      render: (value) => {
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[0];
        } else {
          finalValue = value;
        }
        return finalValue;
      },
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    frontColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 80,
      render: (value, record) => {
        const { setup1, setup2 } = record;
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[0];
        } else {
          finalValue = value;
        }

        let diff;
        if (Array.isArray(setup1) && Array.isArray(setup2)) {
          diff = setup2[0] - setup1[0];
        } else {
          diff = setup2 - setup1;
        }

        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{finalValue}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  // 2. Rear data

  const rearKeys = ["rideHeight", "rearWing", "brakeDuct"];
  const rearDataSource = data.children.filter((item) =>
    rearKeys.includes(item.key)
  );

  const rearColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 100,
      align: "center",
      render: (value) => {
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[1];
        } else {
          finalValue = value;
        }
        return finalValue;
      },
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    rearColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 100,
      align: "center",
      render: (value, record) => {
        const { setup1, setup2 } = record;
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[1];
        } else {
          finalValue = value;
        }

        let diff;
        if (Array.isArray(setup1) && Array.isArray(setup2)) {
          diff = setup2[1] - setup1[1];
        } else {
          diff = setup2 - setup1;
        }

        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{finalValue}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  return (
    <Card type="inner" title="AERO" style={{ marginTop: 8 }}>
      <div
        className="setup-section"
        style={{
          display: "flex",
          background: "white",
          justifyContent: "center",
          padding: 48,
        }}
      >
        <div class="item">
          <Card type="inner" title="Rear" bodyStyle={{ padding: 0 }}>
            <Table
              showHeader={false}
              className="setup-data-table"
              bordered
              pagination={{
                hideOnSinglePage: true,
              }}
              dataSource={rearDataSource}
              columns={rearColumns}
            />
          </Card>
        </div>

        <div
          className="res-no-margin"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 64,
            marginRight: 64,
          }}
        >
          <Chasisicon
            className="setup-chasis-icon"
            transform="rotate(90)"
            style={{
              verticalAlign: "middle",
              fill: "black",
            }}
            width={124}
            height={124}
          />
        </div>

        <div class="item">
          <Card type="inner" title="Front" bodyStyle={{ padding: 0 }}>
            <Table
              showHeader={false}
              bordered
              className="setup-data-table"
              pagination={{
                hideOnSinglePage: true,
              }}
              dataSource={frontDataSource}
              columns={frontColumns}
            />
          </Card>
        </div>
      </div>
    </Card>
  );
};

export default Aero;
