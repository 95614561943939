import { Button } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
  cursor: pointer !important;
  border-color: #6e6f70;
  background: ${(props) => (props.active ? "black" : "#6e6f70")};
  margin-top: 8px;
  margin-left: 8px;
  &:hover {
    border-color: #6e6f70;
    background: black;
  }
  &:focus {
    border-color: #6e6f70;
    background: black;
  }
  &:disabled {
    cursor: not-allowed !important;
  }
`;

export default StyledButton;
