import React from "react";
import { Table, Card } from "antd";

const Electronics = ({ data, mode, isSetup2Loaded }) => {
  const electronicsDataSource = data.children.filter(
    (i) => i.key !== "telemetryLaps"
  );
  const electronicsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 100,
      align: "center",
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    electronicsColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 100,
      align: "center",
      render: (val, record) => {
        const { setup1, setup2 } = record;
        const diff = setup2 - setup1;
        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{val}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  return (
    <Card type="inner" title="ELECTRONICS" style={{ marginTop: 8 }}>
      <div
        className="setup-section"
        style={{
          display: "flex",
          background: "white",
          justifyContent: "center",
          padding: 48,
        }}
      >
        <div class="item">
          <Card
            type="inner"
            title="Electronic Elements"
            bodyStyle={{ padding: 0 }}
          >
            <Table
              showHeader={false}
              className="setup-data-table"
              bordered
              pagination={{
                hideOnSinglePage: true,
              }}
              dataSource={electronicsDataSource}
              columns={electronicsColumns}
            />
          </Card>
        </div>
      </div>
    </Card>
  );
};

export default Electronics;
