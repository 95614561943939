import { cars } from "./config/cars";

const getRange = (val, start, end, step) => {
  let finalValue;
  try {
    if (start === end) {
      finalValue = start || end; // for fixed ranges such as cup cars or lambo evo 2 fast bump
    } else {
      let range = [];
      for (let i = start; i <= end; i = i + step) {
        range.push(Math.round((i + Number.EPSILON) * 100) / 100);
      }
      finalValue = range[val];
    }
  } catch (e) {
    finalValue = 0;
  }
  return finalValue;
};

const getCustomRange = (val, arr) => {
  return arr[val];
};

export const getMappedRangeValuesByCar = (carName, setup, key) => {
  try {
    // Load dynamic car config
    const carConfig = cars[carName];

    const {
      tyrePressureRange,
      tyrePressureStep,
      toeFrontRange,
      toeRearRange,
      toeStep,
      camberFrontRange,
      camberRearRange,
      camberStep,
      casterArr,
      eCUMapRange,
      eCUMapStep,
      brakeTorqueRange,
      brakeTorqueStep,
      brakeBiasRange,
      brakeBiasStep,
      wheelRatesFront,
      wheelRatesRear,
      steerRatioStep,
      steerRatioRange,
      bumpStopRateRange,
      bumpStopFrontRateRange = false,
      bumpStopRearRateRange = false,
      bumpStopRateStep,
      bumpStopRateFrontStep = false,
      bumpStopRateRearStep = false,
      bumpStopWindowFrontRange,
      bumpStopWindowRearRange,
      bumpStopWindowStep,
      preloadRange,
      preloadStep,
      bumpSlowRange,
      reboundSlowRange,
      bumpFastRange,
      reboundFastRange,
      damperStep,
      rideHeightFrontRange,
      rideHeightRearRange,
      rideHeightFrontRange_n24h = [],
      rideHeightRearRange_n24h = [],
      rideHeightStep,
      rearWingRange,
      rearWingStep,
      splitterRange,
      splitterStep,
      brakeDuctRange,
      brakeDuctStep,
    } = carConfig;

    const {
      tyreCompound,
      tyrePressure,
      toe,
      camber,
      casterLF,
      casterRF,
      eCUMap,
      brakeTorque,
      brakeBias,
      steerRatio,
      wheelRate,
      bumpStopRateUp,
      bumpStopWindow,
      preload,
      bumpSlow,
      bumpFast,
      reboundSlow,
      reboundFast,
      rideHeightFront,
      rideHeightRear,
      rearWing,
      splitter,
      brakeDuct,
      fuel,
      tyreSet,
      frontBrakePadCompound,
      rearBrakePadCompound,
      trackBopType,
    } = setup;

    let dynamicRideHeightFrontRange;
    let dynamicRideHeightRearRange;

    if (trackBopType === 58) {
      dynamicRideHeightFrontRange = rideHeightFrontRange_n24h;
      dynamicRideHeightRearRange = rideHeightRearRange_n24h;
    } else {
      dynamicRideHeightFrontRange = rideHeightFrontRange;
      dynamicRideHeightRearRange = rideHeightRearRange;
    }

    const updatedSetupConfig = {
      tyreCompound: getCustomRange(tyreCompound, ["dry", "wet"]),
      tyrePressure: [
        getRange(
          tyrePressure[0],
          tyrePressureRange[0],
          tyrePressureRange[1],
          tyrePressureStep
        ),
        getRange(
          tyrePressure[1],
          tyrePressureRange[0],
          tyrePressureRange[1],
          tyrePressureStep
        ),
        getRange(
          tyrePressure[2],
          tyrePressureRange[0],
          tyrePressureRange[1],
          tyrePressureStep
        ),
        getRange(
          tyrePressure[3],
          tyrePressureRange[0],
          tyrePressureRange[1],
          tyrePressureStep
        ),
      ],
      toe: [
        getRange(toe[0], toeFrontRange[0], toeFrontRange[1], toeStep),
        getRange(toe[1], toeFrontRange[0], toeFrontRange[1], toeStep),
        getRange(toe[2], toeRearRange[0], toeRearRange[1], toeStep),
        getRange(toe[3], toeRearRange[0], toeRearRange[1], toeStep),
      ],
      camber: [
        getRange(
          camber[0],
          camberFrontRange[0],
          camberFrontRange[1],
          camberStep
        ),
        getRange(
          camber[1],
          camberFrontRange[0],
          camberFrontRange[1],
          camberStep
        ),
        getRange(camber[2], camberRearRange[0], camberRearRange[1], camberStep),
        getRange(camber[3], camberRearRange[0], camberRearRange[1], camberStep),
      ],
      casterLF: getCustomRange(casterLF, casterArr),
      casterRF: getCustomRange(casterLF, casterArr),
      eCUMap: getRange(eCUMap, eCUMapRange[0], eCUMapRange[1], eCUMapStep),
      fuel: 2 + fuel,
      tyreSet: getRange(tyreSet, 1, 50, 1),
      frontBrakePadCompound: 1 + frontBrakePadCompound,
      rearBrakePadCompound: 1 + rearBrakePadCompound,
      brakeTorque: getRange(
        brakeTorque,
        brakeTorqueRange[0],
        brakeTorqueRange[1],
        brakeTorqueStep
      ),
      brakeBias: getRange(
        brakeBias,
        brakeBiasRange[0],
        brakeBiasRange[1],
        brakeBiasStep
      ),
      steerRatio: getRange(
        steerRatio,
        steerRatioRange[0],
        steerRatioRange[1],
        steerRatioStep
      ),
      wheelRate: [
        getCustomRange(wheelRate[0], wheelRatesFront),
        getCustomRange(wheelRate[1], wheelRatesFront),
        getCustomRange(wheelRate[2], wheelRatesRear),
        getCustomRange(wheelRate[3], wheelRatesRear),
      ],
      bumpStopRateUp: [
        getRange(
          bumpStopRateUp[0],
          bumpStopFrontRateRange
            ? bumpStopFrontRateRange[0]
            : bumpStopRateRange[0],
          bumpStopFrontRateRange
            ? bumpStopFrontRateRange[1]
            : bumpStopRateRange[1],
          bumpStopRateFrontStep ? bumpStopRateFrontStep : bumpStopRateStep
        ),
        getRange(
          bumpStopRateUp[1],
          bumpStopFrontRateRange
            ? bumpStopFrontRateRange[0]
            : bumpStopRateRange[0],
          bumpStopFrontRateRange
            ? bumpStopFrontRateRange[1]
            : bumpStopRateRange[1],
          bumpStopRateFrontStep ? bumpStopRateFrontStep : bumpStopRateStep
        ),
        getRange(
          bumpStopRateUp[2],
          bumpStopRearRateRange
            ? bumpStopRearRateRange[0]
            : bumpStopRateRange[0],
          bumpStopRearRateRange
            ? bumpStopRearRateRange[1]
            : bumpStopRateRange[1],
          bumpStopRateRearStep ? bumpStopRateRearStep : bumpStopRateStep
        ),
        getRange(
          bumpStopRateUp[3],
          bumpStopRearRateRange
            ? bumpStopRearRateRange[0]
            : bumpStopRateRange[0],
          bumpStopRearRateRange
            ? bumpStopRearRateRange[1]
            : bumpStopRateRange[1],
          bumpStopRateRearStep ? bumpStopRateRearStep : bumpStopRateStep
        ),
      ],
      bumpStopWindow: [
        getRange(
          bumpStopWindow[0],
          bumpStopWindowFrontRange[0],
          bumpStopWindowFrontRange[1],
          bumpStopWindowStep
        ),
        getRange(
          bumpStopWindow[1],
          bumpStopWindowFrontRange[0],
          bumpStopWindowFrontRange[1],
          bumpStopWindowStep
        ),
        getRange(
          bumpStopWindow[2],
          bumpStopWindowRearRange[0],
          bumpStopWindowRearRange[1],
          bumpStopWindowStep
        ),
        getRange(
          bumpStopWindow[3],
          bumpStopWindowRearRange[0],
          bumpStopWindowRearRange[1],
          bumpStopWindowStep
        ),
      ],
      preload: getRange(preload, preloadRange[0], preloadRange[1], preloadStep),
      bumpSlow: [
        getRange(bumpSlow[0], bumpSlowRange[0], bumpSlowRange[1], damperStep),
        getRange(bumpSlow[1], bumpSlowRange[0], bumpSlowRange[1], damperStep),
        getRange(bumpSlow[2], bumpSlowRange[0], bumpSlowRange[1], damperStep),
        getRange(bumpSlow[3], bumpSlowRange[0], bumpSlowRange[1], damperStep),
      ],
      bumpFast: [
        getRange(bumpFast[0], bumpFastRange[0], bumpFastRange[1], damperStep),
        getRange(bumpFast[1], bumpFastRange[0], bumpFastRange[1], damperStep),
        getRange(bumpFast[2], bumpFastRange[0], bumpFastRange[1], damperStep),
        getRange(bumpFast[3], bumpFastRange[0], bumpFastRange[1], damperStep),
      ],
      reboundSlow: [
        getRange(
          reboundSlow[0],
          reboundSlowRange[0],
          reboundSlowRange[1],
          damperStep
        ),
        getRange(
          reboundSlow[1],
          reboundSlowRange[0],
          reboundSlowRange[1],
          damperStep
        ),
        getRange(
          reboundSlow[2],
          reboundSlowRange[0],
          reboundSlowRange[1],
          damperStep
        ),
        getRange(
          reboundSlow[3],
          reboundSlowRange[0],
          reboundSlowRange[1],
          damperStep
        ),
      ],
      reboundFast: [
        getRange(
          reboundFast[0],
          reboundFastRange[0],
          reboundFastRange[1],
          damperStep
        ),
        getRange(
          reboundFast[1],
          reboundFastRange[0],
          reboundFastRange[1],
          damperStep
        ),
        getRange(
          reboundFast[2],
          reboundFastRange[0],
          reboundFastRange[1],
          damperStep
        ),
        getRange(
          reboundFast[3],
          reboundFastRange[0],
          reboundFastRange[1],
          damperStep
        ),
      ],
      rideHeightFront: getRange(
        rideHeightFront,
        dynamicRideHeightFrontRange[0],
        dynamicRideHeightFrontRange[1],
        rideHeightStep
      ),
      rideHeightRear: getRange(
        rideHeightRear,
        dynamicRideHeightRearRange[0],
        dynamicRideHeightRearRange[1],
        rideHeightStep
      ),
      rearWing: getRange(
        rearWing,
        rearWingRange[0],
        rearWingRange[1],
        rearWingStep
      ),
      splitter: getRange(
        splitter,
        splitterRange[0],
        splitterRange[1],
        splitterStep
      ),
      brakeDuct: [
        getRange(
          brakeDuct[0],
          brakeDuctRange[0],
          brakeDuctRange[1],
          brakeDuctStep
        ),
        getRange(
          brakeDuct[1],
          brakeDuctRange[0],
          brakeDuctRange[1],
          brakeDuctStep
        ),
      ],
    };

    return updatedSetupConfig;
  } catch (e) {
    console.log("e", e);
  }
};
