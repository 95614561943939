import React from "react";
import { Upload, Card, Row, Col } from "antd";
import StyledButton from "../StyledButton";
import SetupIcon from "../../assets/SetupIcon";

const { Dragger } = Upload;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const beforeUpload = (file, fileList) => {
  return false;
};

const SetupsUploadSection = (props) => {
  const {
    eventHandlers,
    showSampleDiff,
    clearSampleDiff,
    setup1File,
    setup2File,
    mode,
  } = props;

  const {
    onSetupOneChange,
    onSetupTwoChange,
    onSetupOneRemove,
    onSetupTwoRemove,
    onSwapSetups,
    onModeChange,
  } = eventHandlers;

  const draggerOneProps = {
    accept: ".json",
    className: setup1File ? "has-file" : "no-file",
    name: "setup1",
    maxCount: 1,
    multiple: false,
    fileList: setup1File ? [setup1File] : [],
    beforeUpload: beforeUpload,
    onChange: onSetupOneChange,
    onRemove: onSetupOneRemove,
    customRequest: dummyRequest,
  };

  const draggerTwoProps = {
    accept: ".json",
    className: setup2File ? "has-file" : "no-file",
    name: "setup2",
    disabled: mode === "single" ? true : false,
    maxCount: 1,
    multiple: false,
    fileList: setup2File ? [setup2File] : [],
    beforeUpload: beforeUpload,
    customRequest: dummyRequest,
    onChange: onSetupTwoChange,
    onRemove: onSetupTwoRemove,
  };

  return (
    <>
      <div className="site-card-wrapper" style={{ padding: 16 }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} xl={10}>
            <Card
              title={"Welcome"}
              className="welcome-card"
              bordered={true}
              style={{
                minHeight: 280,
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <p>
                ACCSetupComparator is a web based tool to quickly view and
                compare acc setup(s). Setups are 100% safe as the difference is
                calculated in browser itself without storing the files. Using
                this tool is completely free, refer it to your friends if you
                find it helpful. <a onClick={showSampleDiff}> Click here</a> for
                a sample comparison.
              </p>
              <p>
                To get updates about upcoming cars, features join{" "}
                <a target="_blank" href="https://discord.gg/v9kvwXEmQj">
                  Discord Community.
                </a>{" "}
                If you like my work, support me via{" "}
                <a
                  target="_blank"
                  href="https://www.paypal.com/ncp/payment/WQDPE8Q8LN36L"
                >
                  Paypal.
                </a>{" "}
              </p>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <StyledButton
                  type="primary"
                  active={mode === "single"}
                  shape="round"
                  size={"small"}
                  onClick={() => onModeChange("single")}
                >
                  Single Setup Mode
                </StyledButton>
                <StyledButton
                  type="primary"
                  active={mode === "compare"}
                  shape="round"
                  size={"small"}
                  onClick={() => onModeChange("compare")}
                >
                  Comparison Mode
                </StyledButton>
                <StyledButton
                  type="primary"
                  shape="round"
                  size={"small"}
                  disabled={!setup1File && !setup2File}
                  onClick={clearSampleDiff}
                >
                  Clear Setups
                </StyledButton>
                <StyledButton
                  type="primary"
                  shape="round"
                  size={"small"}
                  onClick={onSwapSetups}
                  disabled={mode === "single" || !setup1File || !setup2File}
                >
                  Swap setups
                </StyledButton>
              </div>
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: 8 }}
              ></div>
            </Card>
          </Col>
          <Col
            xs={24}
            sm={mode === "compare" ? 12 : 24}
            xl={mode === "compare" ? 7 : 14}
          >
            <Card
              title={"Setup 1"}
              bordered={true}
              className="setup1-card"
              style={{
                textAlign: "center",
                minHeight: 280,
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Dragger {...draggerOneProps}>
                <p className="ant-upload-drag-icon">
                  <SetupIcon style={{ width: 48, height: 48 }} />
                </p>
                <p className="ant-upload-text">{`click or drag to ${
                  setup1File ? "replace" : "load"
                } setup 1`}</p>
              </Dragger>
            </Card>
          </Col>
          {mode === "compare" && (
            <Col xs={24} sm={12} xl={7}>
              <Card
                title={"Setup 2"}
                bordered={true}
                className={`setup2-card ${mode === "single" ? "disabled" : ""}`}
                style={{
                  minHeight: 280,
                  textAlign: "center",
                  boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                }}
              >
                <Dragger {...draggerTwoProps}>
                  <p className="ant-upload-drag-icon">
                    <SetupIcon style={{ width: 48, height: 48 }} />
                  </p>
                  <p className="ant-upload-text">{`click or drag to ${
                    setup2File ? "replace" : "load"
                  } setup 2`}</p>
                </Dragger>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default SetupsUploadSection;
