import React from "react";

function AccCompLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 511.816 511.816"
      version="1.1"
      viewBox="0 0 511.816 511.816"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="black"
        d="M511.644 255.808c0-.064-.008-.12-.008-.184h-.008c-.104-70.496-28.688-134.312-74.88-180.552C390.412 28.688 326.38 0 255.636 0v511.808c70.736 0 134.776-28.696 181.112-75.072 46.192-46.24 74.776-110.056 74.88-180.552h.008c0-.064.008-.12.008-.184v-.096-.096z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M75.068 75.08C28.876 121.32.292 185.136.188 255.632H.18c0 .064-.008.12-.008.184v.192c0 .064.008.12.008.184h.008c.104 70.496 28.688 134.312 74.88 180.552 46.336 46.384 110.368 75.072 181.112 75.072V0C185.436 0 121.396 28.696 75.068 75.08z"
      ></path>
      <path d="M112.412 307.328h18.36V199.344l-19.656 17.328-10.384-12.144L136.7 167.08h17.888l-.096.176.096-.088V307.32h18.256v18.08h-60.44v-18.072h.008z"></path>
      <path
        fill="#fff"
        d="M337.78 311.32l34.944-67.296c3.216-6.128 5.832-11.472 7.832-16.04s3.584-8.576 4.728-12.008c1.144-3.432 1.928-6.408 2.368-8.944.432-2.528.648-4.824.648-6.856 0-3.208-.296-5.912-.88-8.112-.584-2.184-1.456-3.952-2.592-5.28a9.46 9.46 0 00-4.264-2.832c-1.704-.552-3.664-.832-5.888-.832-5.44 0-9.376 2.24-11.816 6.728-2.448 4.48-3.664 10.92-3.664 19.32v8.992H340.66a416.619 416.619 0 01-1.76-9.504c-.496-2.872-.744-6.008-.744-9.408 0-4.016.696-8.016 2.088-12.008 1.392-3.984 3.56-7.584 6.528-10.8s6.752-5.808 11.36-7.792c4.6-1.976 10.12-2.96 16.536-2.96 6.92 0 12.84.808 17.76 2.408 4.904 1.608 8.944 3.952 12.096 7.048s5.456 6.856 6.904 11.304c1.456 4.456 2.176 9.496 2.176 15.112 0 3.216-.296 6.384-.88 9.504s-1.56 6.456-2.92 10.008-3.12 7.432-5.28 11.632c-2.16 4.2-4.824 9.024-7.968 14.464l-31.24 59.328h46.256V325.4H337.78v-14.08z"
      ></path>
    </svg>
  );
}

export default AccCompLogo;
