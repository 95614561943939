import React from "react";
import { Table, Card } from "antd";
import Chasisicon from "../../../assets/Chasisicon";

const TyreCard = ({ data, mode, isSetup2Loaded }) => {
  const filteredData = data.children.filter(
    (item) => item.key !== "tyreCompound"
  );

  let leftFrontDataSource = [];
  let leftRearDataSource = [];
  let rightFrontDataSource = [];
  let rightRearDataSource = [];

  filteredData.forEach((item) => {
    const { name, setup1 = [], setup2 = [] } = item;

    setup1.forEach((_, index) => {
      if (index === 0) {
        leftFrontDataSource.push({
          name,
          setup1: setup1[index],
          setup2: setup2[index],
        });
      } else if (index === 1) {
        rightFrontDataSource.push({
          name,
          setup1: setup1[index],
          setup2: setup2[index],
        });
      } else if (index === 2) {
        leftRearDataSource.push({
          name,
          setup1: setup1[index],
          setup2: setup2[index],
        });
      } else if (index === 3) {
        rightRearDataSource.push({
          name,
          setup1: setup1[index],
          setup2: setup2[index],
        });
      } else {
        console.log("invalid index");
      }
    });
  });

  const tyreColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 100,
      align: "center",
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    tyreColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 100,
      align: "center",
      render: (val, record) => {
        const { setup1, setup2 } = record;
        const diff = setup2 - setup1;
        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{val}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  return (
    <Card type="inner" title="TYRES" style={{ marginTop: 8 }}>
      <div
        className="setup-section"
        style={{
          display: "flex",
          background: "white",
          justifyContent: "center",
          padding: 48,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div class="item">
            <Card type="inner" title="Left Front" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={leftFrontDataSource}
                columns={tyreColumns}
              />
            </Card>
          </div>
          <div className="item res-no-margin" style={{ marginTop: 64 }}>
            <Card type="inner" title="Left Rear" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                bordered
                className="setup-data-table"
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={leftRearDataSource}
                columns={tyreColumns}
              />
            </Card>
          </div>
        </div>
        <div
          className="item res-no-margin"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 64,
            marginRight: 64,
          }}
        >
          <Chasisicon
            className="setup-chasis-icon"
            style={{ verticalAlign: "middle", fill: "black" }}
            width={124}
            height={124}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="item">
            <Card type="inner" title="Right Front" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                bordered
                className="setup-data-table"
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={rightFrontDataSource}
                columns={tyreColumns}
              />
            </Card>
          </div>
          <div className="item res-no-margin" style={{ marginTop: 64 }}>
            <Card type="inner" title="Right Rear" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                bordered
                className="setup-data-table"
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={rightRearDataSource}
                columns={tyreColumns}
              />
            </Card>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default TyreCard;
