/*
TODO
    1. Add support for GT4 cars.
    2. Add missing 2015 cars.
    3. Move common config to top and consume in individual car config.
*/

export const cars = {
  audi_r8_lms_gt2: {
    fullName: "Audi R8 LMS GT2",
    year: 2022,
    category: "gt2",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-3.5, -1.5],
    camberRearRange: [-3.0, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.6, 6.8, 7.0, 7.2, 7.4, 7.6, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 9.0, 9.2, 9.4,
      9.6, 9.8, 10.0, 10.2, 10.4, 10.6, 10.8, 11.0, 11.2, 11.4, 11.6, 11.8,
      12.0, 12.2, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 70],
    brakeBiasStep: 0.2,
    steerRatioRange: [12, 16],
    steerRatioStep: 1,
    wheelRatesFront: [142000, 160000],
    wheelRatesRear: [146000, 163000],
    bumpStopRateRange: [300, 2000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 0],
    bumpStopWindowRearRange: [0, 0],
    bumpStopWindowStep: 1,
    preloadRange: [10, 80],
    preloadStep: 10,
    bumpSlowRange: [1, 22],
    reboundSlowRange: [1, 22],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 20],
    damperStep: 1,
    rideHeightFrontRange: [80, 100],
    rideHeightRearRange: [80, 110],
    rideHeightFrontRange_n24h: [80, 100],
    rideHeightRearRange_n24h: [80, 110],
    rideHeightStep: 1,
    rearWingRange: [0, 4],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  ktm_xbow_gt2: {
    fullName: "KTM X-BOW GT2",
    year: 2021,
    category: "gt2",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-3.5, -1.5],
    camberRearRange: [-3.0, -1.0],
    camberStep: 0.1,
    casterArr: [
      3.2, 3.4, 3.6, 3.9, 4.1, 4.3, 4.5, 4.7, 4.9, 5.2, 5.4, 5.6, 5.8, 6.0, 6.2,
      6.5, 6.7, 6.9, 7.1, 7.3, 7.5, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 9.0, 9.2, 9.5,
      9.7, 9.9, 10.1, 10.3, 10.5, 10.7, 10.9, 11.1, 11.4, 11.6, 11.8,
    ],
    eCUMapRange: [0, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [44, 65],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [87000, 97000, 107000, 117000, 127000],
    wheelRatesRear: [81000, 91000, 101000, 111000, 121000, 131000],
    bumpStopRateRange: [300, 1000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 52],
    bumpStopWindowRearRange: [0, 60],
    bumpStopWindowStep: 1,
    preloadRange: [10, 80],
    preloadStep: 10,
    bumpSlowRange: [0, 24],
    reboundSlowRange: [0, 24],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 24],
    damperStep: 1,
    rideHeightFrontRange: [75, 100],
    rideHeightRearRange: [200, 250],
    rideHeightFrontRange_n24h: [75, 100],
    rideHeightRearRange_n24h: [200, 250],
    rideHeightStep: 1,
    rearWingRange: [1, 9],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  maserati_mc20_gt2: {
    fullName: "Maserati GT2",
    year: 2023,
    category: "gt2",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-3.5, -1.5],
    camberRearRange: [-3.0, -1.0],
    camberStep: 0.1,
    casterArr: [8.5, 8.7, 8.9, 9.1, 9.2, 9.4, 9.6, 9.8, 9.9, 10.1, 10.3],
    eCUMapRange: [0, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [55, 75],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 15],
    steerRatioStep: 1,
    wheelRatesFront: [165180, 179540, 193900],
    wheelRatesRear: [173913, 189036, 204158],
    bumpStopRateRange: [300, 1500],
    bumpStopRearRateRange: [400, 5000],
    bumpStopRateStep: 100,
    bumpStopRateRearStep: 200,
    bumpStopWindowFrontRange: [0, 15],
    bumpStopWindowRearRange: [0, 34],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 11],
    damperStep: 1,
    rideHeightFrontRange: [80, 100],
    rideHeightRearRange: [80, 110],
    rideHeightFrontRange_n24h: [80, 100],
    rideHeightRearRange_n24h: [80, 110],
    rideHeightStep: 1,
    rearWingRange: [1, 16],
    rearWingStep: 1,
    splitterRange: [0, 2],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  mercedes_amg_gt2: {
    fullName: "Mercedes AMG GT2",
    year: 2023,
    category: "gt2",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.2, 0.2],
    toeRearRange: [0.0, 0.31],
    toeStep: 0.01,
    camberFrontRange: [-3.5, -1.5],
    camberRearRange: [-3.0, -1.0],
    camberStep: 0.1,
    casterArr: [
      9.2, 9.4, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5, 10.7, 10.8, 11.0, 11.2, 11.4,
      11.6, 11.8, 11.9, 12.1, 12.3, 12.5, 12.7, 12.8, 13.0, 13.2, 13.4, 13.6,
      13.7, 13.9, 14.1, 14.3, 14.5, 14.6, 14.8, 15.0, 15.2, 15.4, 15.5, 15.7,
      15.9, 16.1, 16.2, 16.4,
    ],
    eCUMapRange: [0, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [55, 64.9],
    brakeBiasStep: 0.3,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [78000, 88000, 104000],
    wheelRatesRear: [66000, 76000, 86000],
    bumpStopRateRange: [1000, 1000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 0],
    bumpStopWindowRearRange: [0, 0],
    bumpStopWindowStep: 1,
    preloadRange: [10, 80],
    preloadStep: 10,
    bumpSlowRange: [0, 24],
    reboundSlowRange: [0, 24],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 24],
    damperStep: 1,
    rideHeightFrontRange: [122, 140],
    rideHeightRearRange: [140, 160],
    rideHeightFrontRange_n24h: [122, 140],
    rideHeightRearRange_n24h: [140, 160],
    rideHeightStep: 1,
    rearWingRange: [1, 8],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  porsche_935: {
    fullName: "Porsche 935",
    year: 2019,
    category: "gt2",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-3.5, -1.5],
    camberRearRange: [-3.0, -1.0],
    camberStep: 0.1,
    casterArr: [
      7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8.0, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7,
      8.8, 8.9, 9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1,
      10.2, 10.3,
    ],
    casterRange: [7.3, 10.3],
    casterStep: 0.1,
    eCUMapRange: [1, 1],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 65],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [0, 1, 2, 3],
    wheelRatesRear: [0, 1, 2, 3],
    bumpStopRateRange: [1000, 1000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 120],
    bumpStopWindowRearRange: [0, 200],
    bumpStopWindowStep: 1,
    preloadRange: [0, 0],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [90, 110],
    rideHeightRearRange: [160, 190],
    rideHeightFrontRange_n24h: [90, 110],
    rideHeightRearRange_n24h: [160, 190],
    rideHeightStep: 1,
    rearWingRange: [1, 10],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  porsche_991_gt2_rs_mr: {
    fullName: "Porsche 991II GT2 RS CS Evo",
    year: 2023,
    category: "gt2",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-3.5, -1.5],
    camberRearRange: [-3.0, -1.0],
    camberStep: 0.1,
    casterArr: [
      7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8.0, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7,
      8.8, 8.9, 9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10, 10.1,
      10.2, 10.3,
    ],
    casterRange: [7.3, 10.3],
    casterStep: 0.1,
    eCUMapRange: [1, 1],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 65],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [0, 1, 2, 3],
    wheelRatesRear: [0, 1, 2, 3],
    bumpStopRateRange: [1000, 1000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 120],
    bumpStopWindowRearRange: [0, 200],
    bumpStopWindowStep: 1,
    preloadRange: [0, 0],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [100, 120],
    rideHeightRearRange: [167, 185],
    rideHeightFrontRange_n24h: [100, 120],
    rideHeightRearRange_n24h: [167, 185],
    rideHeightStep: 1,
    rearWingRange: [1, 10],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  //Include 2013 AMR v12
  amr_v8_vantage_gt3: {
    fullName: "AMR V8 Vantage GT3",
    year: 2019,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      10.7, 10.9, 11.1, 11.3, 11.5, 11.6, 11.8, 12.0, 12.2, 12.4, 12.5, 12.7,
      12.9, 13.1, 13.3, 13.4, 13.6, 13.8, 14.0, 14.2, 14.3, 14.5, 14.7, 14.9,
      15.0, 15.2, 15.4, 15.6, 15.7, 15.9, 16.1,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [57, 78],
    brakeBiasStep: 0.2,
    steerRatioRange: [14, 18],
    steerRatioStep: 1,
    wheelRatesFront: [
      115000, 125000, 135000, 145000, 155000, 165000, 175000, 185000,
    ],
    wheelRatesRear: [
      105000, 115000, 125000, 135000, 145000, 155000, 165000, 175000, 185000,
      195000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 23],
    bumpStopWindowRearRange: [0, 68],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [53, 80],
    rideHeightRearRange: [53, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 10],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  //Include Audi R8 LMS 2015
  audi_r8_lms_evo: {
    fullName: "Audi R8 LMS evo",
    year: 2019,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      8.8, 9.1, 9.3, 9.6, 9.8, 10.0, 10.3, 10.5, 10.8, 11.0, 11.2, 11.5, 11.7,
      11.9, 12.2, 12.4, 12.6, 12.9, 13.1, 13.3, 13.6, 13.8, 14.0, 14.3, 14.5,
      14.7, 15.0, 15.2, 15.4, 15.7, 15.9, 16.1, 16.3, 16.6, 16.8,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 71],
    brakeBiasStep: 0.2,
    steerRatioRange: [12, 18],
    steerRatioStep: 1,
    wheelRatesFront: [112000, 132000, 153000, 174000, 185000, 195000],
    wheelRatesRear: [124000, 144000, 163000, 173000, 183000, 202000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 32],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 5],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  audi_r8_lms_evo_ii: {
    fullName: "Audi R8 LMS evo ii",
    year: 2022,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      8.8, 9.1, 9.3, 9.6, 9.8, 10.0, 10.3, 10.5, 10.8, 11.0, 11.2, 11.5, 11.7,
      11.9, 12.2, 12.4, 12.6, 12.9, 13.1, 13.3, 13.6, 13.8, 14.0, 14.3, 14.5,
      14.7, 15.0, 15.2, 15.4, 15.7, 15.9, 16.1, 16.3, 16.6, 16.8,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 71],
    brakeBiasStep: 0.2,
    steerRatioRange: [12, 18],
    steerRatioStep: 1,
    wheelRatesFront: [112000, 132000, 153000, 174000, 185000, 195000],
    wheelRatesRear: [124000, 144000, 163000, 173000, 183000, 202000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 32],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 5],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  //Include Bentley 2015
  bentley_continental_gt3_2018: {
    fullName: "Bentley Continental GT3",
    year: 2018,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      8.3, 8.5, 8.8, 9.0, 9.2, 9.5, 9.7, 10.0, 10.2, 10.5, 10.7, 10.9, 11.2,
      11.4, 11.7, 11.9, 12.1, 12.4, 12.6, 12.9, 13.1, 13.3, 13.6, 13.8, 14.0,
      14.3, 14.5, 14.8, 15.0, 15.2, 15.5,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [57, 78],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 15],
    steerRatioStep: 1,
    wheelRatesFront: [
      115000, 120000, 125000, 130000, 135000, 140000, 145000, 150000, 155000,
      160000, 165000, 175000, 180000, 185000,
    ],
    wheelRatesRear: [
      95000, 100000, 105000, 110000, 115000, 120000, 125000, 130000, 135000,
      140000, 145000, 150000, 155000, 160000, 165000, 175000, 180000, 185000,
      190000, 195000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 30],
    bumpStopWindowRearRange: [0, 58],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 10],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  bmw_m4_gt3: {
    category: "gt3",
    year: 2022,
    fullName: "BMW M4 GT3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.2, 0.2],
    toeRearRange: [0.0, 0.31],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9,
      9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.4, 10.6, 10.8, 11.0, 11.2, 11.4,
      11.6, 11.8, 12.0, 12.4, 12.6, 12.8, 13.0, 13.2, 13.4, 13.6, 13.7, 13.9,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [48.5, 68.0],
    brakeBiasStep: 0.3,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [105000, 120000, 135000, 150000, 165000, 180000],
    wheelRatesRear: [90000, 105000, 120000, 135000, 150000, 165000],
    bumpStopRateRange: [200, 1500],
    bumpStopRateStep: 50,
    bumpStopWindowFrontRange: [0, 32],
    bumpStopWindowRearRange: [0, 60],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [50, 70],
    rideHeightRearRange: [50, 80],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 8],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  bmw_m6_gt3: {
    category: "gt3",
    year: 2017,
    fullName: "BMW M6 GT3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.2, 0.2],
    toeRearRange: [0.0, 0.31],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.7, 6.9, 7.1, 7.4, 7.6, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 9.0, 9.2, 9.4, 9.7,
      9.9, 10.1, 10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1,
      12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5, 13.7, 13.9, 14.2, 14.4, 14.6,
      14.8, 15.0,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [47.5, 67.0],
    brakeBiasStep: 0.3,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [136000, 146000, 156000, 166000, 176000, 186000],
    wheelRatesRear: [96000, 106000, 116000, 126000, 136000, 146000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 28],
    bumpStopWindowRearRange: [0, 56],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [52, 70],
    rideHeightRearRange: [52, 80],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 7],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  ferrari_296_gt3: {
    fullName: "Ferrari 296 GT3",
    year: 2023,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      8.5, 8.7, 8.9, 9.1, 9.2, 9.4, 9.6, 9.8, 9.9, 10.1, 10.3, 10.5, 10.6, 10.8,
      11.0, 11.2, 11.3, 11.5, 11.7, 11.9, 12.0, 12.2, 12.4, 12.6, 12.7, 12.9,
      13.1, 13.3, 13.4, 13.6, 13.8,
    ],
    eCUMapRange: [1, 12],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 75],
    brakeBiasStep: 0.2,
    steerRatioRange: [13, 17],
    steerRatioStep: 1,
    wheelRatesFront: [
      163769, 170068, 176367, 182666, 188964, 195263, 201562, 207861, 214160,
    ],
    wheelRatesRear: [
      122091, 129273, 136455, 143637, 150818, 158000, 165182, 172364, 179546,
    ],
    bumpStopRateRange: [300, 1500],
    bumpStopFrontRateRange: [300, 1500],
    bumpStopRearRateRange: [400, 5000],
    bumpStopRateStep: 100,
    bumpStopRateFrontStep: 100,
    bumpStopRateRearStep: 200,
    bumpStopWindowFrontRange: [0, 16],
    bumpStopWindowRearRange: [0, 38],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 12],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [50, 75],
    rideHeightRearRange: [50, 75],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [0, 12],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  //Include 2020 GT3 EVp
  ferrari_488_gt3_evo: {
    fullName: "Ferrari 488 GT3 Evo",
    year: 2020,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      5.0, 5.1, 5.3, 5.5, 5.6, 5.8, 6.0, 6.1, 6.3, 6.5, 6.6, 6.8, 7.0, 7.1, 7.3,
      7.5, 7.6, 7.8, 8.0, 8.1, 8.3, 8.5, 8.6, 8.8, 9.0, 9.1, 9.3, 9.5, 9.6, 9.8,
      9.9, 10.1, 10.3, 10.4, 10.6, 10.8, 10.9, 11.1, 11.2, 11.4, 11.6, 11.7,
      11.9, 12.1, 12.2, 12.4, 12.5, 12.7, 12.9, 13.0, 13.2, 13.3, 13.5, 13.7,
      13.8, 14.0, 14.1, 14.3, 14.4, 14.6, 14.8, 14.9, 15.1, 15.2, 15.4, 15.5,
      15.7, 15.9, 16.0, 16.2, 16.3, 16.5, 16.6, 16.8, 16.9, 17.1, 17.3, 17.4,
      17.6, 17.7, 17.9, 18.0, 18.2, 18.3, 18.5, 18.6, 18.8, 18.9, 19.1, 19.2,
      19.4, 19.5, 19.7, 19.8, 20.0, 20.1, 20.3, 20.4, 20.6,
    ],
    eCUMapRange: [1, 12],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [47, 68],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [
      94000, 101000, 107000, 113000, 120000, 126000, 138600, 151000, 163800,
      176000, 189000,
    ],
    wheelRatesRear: [
      106000, 113000, 120000, 127000, 134000, 141000, 155000, 169500, 183600,
      198000, 212000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 19],
    bumpStopWindowRearRange: [0, 60],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 12],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [55, 80],
    rideHeightRearRange: [55, 80],
    rideHeightFrontRange_n24h: [70, 100],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 14],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  ford_mustang_gt3: {
    category: "gt3",
    fullName: "Ford Mustang GT3",
    year: 2024,
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.2, 0.2],
    toeRearRange: [-0.1, 0.3],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      5.3,
      5.5,
      5.7,
      5.9,
      6.1,
      6.2,
      6.4,
      6.6,
      6.8,
      6.9,
      7.1,
      7.3,
      7.5,
      7.6,
      7.8,
      8.0,
      8.2,
      8.3,
      8.5,
      8.7,
      8.9,
      9.0,
      9.2,
      9.4,
      9.6,
      9.7,
      9.9,
      10.1,
      10.3,
      10.4,
      10.6,
      10.8,
      10.9,
      11.1,
      11.3,
      11.5,
      11.6,
      11.8,
      12.0,
      12.1,
      12.3,
      ,
    ],
    eCUMapRange: [1, 3],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [48.5, 68.0],
    brakeBiasStep: 0.3,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [105000, 120000, 135000, 150000, 165000, 180000],
    wheelRatesRear: [90000, 105000, 120000, 135000, 150000, 165000],
    bumpStopRateRange: [200, 2500],
    bumpStopRateStep: 50,
    bumpStopWindowFrontRange: [0, 32],
    bumpStopWindowRearRange: [0, 60],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 11],
    reboundFastRange: [0, 11],
    damperStep: 1,
    rideHeightFrontRange: [50, 70],
    rideHeightRearRange: [50, 80],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [0, 8],
    rearWingStep: 1,
    splitterRange: [0, 1],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  //TODO: Add honda nsx 2017
  honda_nsx_gt3_evo: {
    fullName: "Honda NSX GT3 Evo",
    year: 2019,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [7.2, 7.4, 7.6, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 8.9, 9.1],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [44.0, 65.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 15],
    steerRatioStep: 1,
    wheelRatesFront: [
      73000, 79080, 85160, 91240, 97320, 103400, 109480, 115560, 121640, 127720,
      133800, 139880, 145960, 152040, 158120, 164200, 170280,
    ],
    wheelRatesRear: [
      126800, 134700, 142600, 150500, 158400, 166300, 174200, 182100, 190000,
      197900, 205800, 213700, 221600, 229500, 237400, 245300, 253200,
    ],
    bumpStopRateRange: [200, 600],
    bumpStopRateStep: 50,
    bumpStopWindowFrontRange: [0, 35],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 16],
    reboundSlowRange: [0, 16],
    bumpFastRange: [0, 15],
    reboundFastRange: [0, 15],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 100],
    rideHeightStep: 1,
    rearWingRange: [1, 12],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add lambo 2015
  lamborghini_huracan_gt3_evo: {
    fullName: "Lamborghini Huracan GT3 Evo",
    year: 2019,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.2, 6.5, 6.7, 7.0, 7.3, 7.5, 7.8, 8.1, 8.3, 8.6, 8.9, 9.1, 9.4, 9.6, 9.9,
      10.2, 10.4, 10.7, 10.9, 11.2, 11.5, 11.7, 12.0, 12.2, 12.5, 12.8, 13.0,
      13.3, 13.5, 13.8, 14.0, 14.3, 14.5, 14.8, 15.0,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 71.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 15],
    steerRatioStep: 1,
    wheelRatesFront: [121000, 144000, 167000, 190000, 201000, 212000],
    wheelRatesRear: [117000, 136000, 154000, 164000, 173000, 191000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 31],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [0, 12],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  lamborghini_huracan_gt3_evo2: {
    fullName: "Lamborghini Huracan GT3 EVO2",
    year: 2023,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.2, 6.5, 6.7, 7.0, 7.3, 7.5, 7.8, 8.1, 8.3, 8.6, 8.9, 9.1, 9.4, 9.6, 9.9,
      10.2, 10.4, 10.7, 10.9, 11.2, 11.5, 11.7, 12.0, 12.2, 12.5, 12.8, 13.0,
      13.3, 13.5, 13.8, 14.0, 14.3, 14.5, 14.8, 15.0,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 71.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 15],
    steerRatioStep: 1,
    wheelRatesFront: [121000, 144000, 167000, 190000, 201000, 212000],
    wheelRatesRear: [117000, 136000, 154000, 164000, 173000, 191000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 31],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [0, 12],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  lexus_rc_f_gt3: {
    fullName: "Lexus RC F GT3",
    year: 2016,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      7.9, 8.1, 8.3, 8.5, 8.7, 8.8, 9.0, 9.2, 9.4, 9.6, 9.8, 10.0, 10.2, 10.4,
      10.6, 10.8, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.6,
      12.8, 13.0, 13.2, 13.4, 13.6,
    ],
    eCUMapRange: [1, 5],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50.0, 71.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [96000, 115000, 134000, 154000, 173000, 192000],
    wheelRatesRear: [87000, 112000, 136000, 154000, 175000, 210000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 26],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 160],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [50, 80],
    rideHeightRearRange: [65, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [1, 16],
    rearWingStep: 1,
    splitterRange: [1, 1],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add mclaren 2015
  mclaren_720s_gt3: {
    fullName: "McLaren 720S GT3",
    year: 2019,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.48, 0.44],
    toeRearRange: [-0.1, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [5.3, 5.6, 5.8, 6.0, 6.3, 6.5, 6.8, 7.0, 7.3, 7.5, 7.8, 8.0],
    eCUMapRange: [1, 13],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [47.0, 68.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [
      118000, 134000, 150000, 166000, 182000, 198000, 214000, 230000,
    ],
    wheelRatesRear: [
      114000, 128000, 142000, 156000, 170000, 184000, 198000, 212000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 40],
    bumpStopWindowRearRange: [0, 65],
    bumpStopWindowStep: 1,
    preloadRange: [20, 160],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [50, 80],
    rideHeightRearRange: [64, 105],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [1, 8],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  mclaren_720s_gt3_evo: {
    fullName: "McLaren 720S GT3 Evo",
    year: 2023,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.48, 0.44],
    toeRearRange: [-0.1, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      5.3, 5.6, 5.8, 6.0, 6.3, 6.5, 6.8, 7.0, 7.3, 7.5, 7.8, 8.0, 8.2, 8.5, 8.7,
      9.0, 9.2, 9.4, 9.7, 9.9, 10.2, 10.4, 10.7, 10.9, 11.1, 11.4, 11.6, 11.8,
      12.1, 12.3, 12.6, 12.8, 13.0, 13.3, 13.5, 13.7, 14.0, 14.2, 14.4, 14.7,
      14.9, 15.1, 15.4, 15.6, 15.8, 16.0, 16.3,
    ],
    eCUMapRange: [1, 13],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [47.0, 68.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [
      118000, 134000, 150000, 166000, 182000, 198000, 214000, 230000,
    ],
    wheelRatesRear: [
      83000, 97000, 111000, 125000, 139000, 153000, 167000, 181000, 195000,
      209000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 40],
    bumpStopWindowRearRange: [0, 30],
    bumpStopWindowStep: 1,
    preloadRange: [20, 160],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 39],
    reboundFastRange: [0, 39],
    damperStep: 1,
    rideHeightFrontRange: [50, 80],
    rideHeightRearRange: [64, 105],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [1, 12],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add Mercedes 2015
  mercedes_amg_gt3_evo: {
    category: "gt3",
    fullName: "Mercedes-AMG GT3",
    year: 2020,
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.0, 6.2, 6.4, 6.6, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.2, 8.4, 8.6,
      8.8, 9.0, 9.2, 9.4, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5, 10.7, 10.8, 11.0,
      11.2, 11.4, 11.6, 11.8, 11.9, 12.1, 12.3, 12.5, 12.7, 12.8, 13.0, 13.2,
      13.4, 13.6, 13.7, 13.9, 14.1,
    ],
    eCUMapRange: [1, 3],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 71],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [130000, 143000, 155000, 171000, 187000, 202000],
    wheelRatesRear: [
      71000, 83000, 95000, 107000, 119000, 131000, 155000, 169500, 183600,
      198000, 212000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 31],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 12],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [50, 88],
    rideHeightRearRange: [67, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [1, 11],
    rearWingStep: 1,
    splitterRange: [1, 11],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add nissan 2015
  nissan_gt_r_gt3_2018: {
    fullName: "Nissan GT-R Nismo GT3",
    year: 2018,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.2, 0.2],
    toeRearRange: [0.0, 0.31],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      12.5, 12.6, 12.8, 13.0, 13.2, 13.4, 13.6, 13.8, 13.9, 14.1, 14.3, 14.5,
      14.7, 14.9, 15.0, 15.2, 15.4, 15.6, 15.8, 16.0, 16.1, 16.3, 16.5, 16.7,
      16.9, 17.0, 17.2, 17.4, 17.6, 17.8, 17.9, 18.1, 18.3, 18.5, 18.6, 18.8,
      18.9, 19.0, 19.2, 19.3, 19.5, 19.7,
    ],
    eCUMapRange: [1, 4],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [47.5, 67.0],
    brakeBiasStep: 0.3,
    steerRatioRange: [12, 17],
    steerRatioStep: 1,
    wheelRatesFront: [136000, 146000, 156000, 166000, 176000, 186000],
    wheelRatesRear: [96000, 106000, 116000, 126000, 136000, 146000],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 37],
    bumpStopWindowRearRange: [0, 56],
    bumpStopWindowStep: 1,
    preloadRange: [20, 160],
    preloadStep: 10,
    bumpSlowRange: [0, 40],
    reboundSlowRange: [0, 40],
    bumpFastRange: [0, 49],
    reboundFastRange: [0, 49],
    damperStep: 1,
    rideHeightFrontRange: [55, 70],
    rideHeightRearRange: [55, 80],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [0, 4],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add Porsche 2018
  porsche_991ii_gt3_r: {
    fullName: "Porsche 991II GT3 R",
    year: 2019,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      4.4, 4.6, 4.8, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
      7.5, 7.7, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 9.0, 9.2, 9.4, 9.6, 9.8, 10.0,
      10.2, 10.4, 10.6, 10.8, 11.0, 11.2, 11.4, 11.6, 11.8, 12.0, 12.2, 12.4,
    ],
    casterRange: [4.4, 12.4],
    casterStep: 0.2,
    eCUMapRange: [1, 10],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [43, 64],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [
      100500, 110000, 114000, 119000, 127000, 137000, 141500, 146000, 155000,
      173500,
    ],
    wheelRatesRear: [
      137000, 149500, 156000, 162000, 174500, 187000, 193000, 199500, 212000,
      237000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 50],
    bumpStopWindowRearRange: [0, 50],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 17],
    reboundSlowRange: [0, 17],
    bumpFastRange: [0, 12],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [53, 85],
    rideHeightRearRange: [55, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [0, 12],
    rearWingStep: 1,
    splitterRange: [0, 6],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  porsche_992_gt3_r: {
    fullName: "Porsche 992 GT3 R",
    year: 2023,
    category: "gt3",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.0, -1.5],
    camberRearRange: [-3.5, -1.0],
    camberStep: 0.1,
    casterArr: [
      6.5, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 9.0, 9.2,
      9.4, 9.6, 9.8, 10.0, 10.2, 10.4, 10.6, 10.8, 11.0, 11.2, 11.4, 11.6, 11.8,
      12.0, 12.2, 12.4,
    ],
    casterRange: [6.5, 12.4],
    casterStep: 0.2,
    eCUMapRange: [1, 10],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [43, 64],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [
      100500, 110000, 114000, 119000, 127000, 137000, 141500, 146000, 155000,
      173500,
    ],
    wheelRatesRear: [
      137000, 149500, 156000, 162000, 174500, 187000, 193000, 199500, 212000,
      237000,
    ],
    bumpStopRateRange: [300, 2500],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 49],
    bumpStopWindowRearRange: [0, 50],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [0, 17],
    reboundSlowRange: [0, 17],
    bumpFastRange: [0, 12],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [53, 85],
    rideHeightRearRange: [55, 90],
    rideHeightFrontRange_n24h: [70, 90],
    rideHeightRearRange_n24h: [70, 90],
    rideHeightStep: 1,
    rearWingRange: [0, 12],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add Reiter 2018
  // TODO: Add GT4 Cars 10
  porsche_718_cayman_gt4_mr: {
    fullName: "Porsche 718 Cayman GT4 Clubsport",
    year: 2019,
    category: "gt4",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-5.0, -2.5],
    camberRearRange: [-5.0, -1.5],
    camberStep: 0.1,
    casterArr: [
      7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8.0, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7,
      8.8, 8.9, 9.0, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1, 10.2,
    ],
    casterRange: [4.4, 12.4],
    casterStep: 0.2,
    eCUMapRange: [1, 1],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [45, 60],
    brakeBiasStep: 0.2,
    steerRatioRange: [15, 20],
    steerRatioStep: 1,
    wheelRatesFront: [99000, 108000, 114000, 116000, 124000, 173500],
    wheelRatesRear: [91000, 99000, 108000, 116000, 124000],
    bumpStopRateRange: [300, 1000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 0],
    bumpStopWindowRearRange: [0, 0],
    bumpStopWindowStep: 1,
    preloadRange: [10, 80],
    preloadStep: 10,
    bumpSlowRange: [0, 17],
    reboundSlowRange: [0, 17],
    bumpFastRange: [0, 16],
    reboundFastRange: [0, 16],
    damperStep: 1,
    rideHeightFrontRange: [111, 150],
    rideHeightRearRange: [99, 150],
    rideHeightFrontRange_n24h: [106, 150],
    rideHeightRearRange_n24h: [94, 150],
    rideHeightStep: 1,
    rearWingRange: [0, 4],
    rearWingStep: 1,
    splitterRange: [0, 5],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  porsche_991ii_gt3_cup: {
    fullName: "Porsche 991II GT3 Cup",
    year: 2017,
    category: "cup",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-6.5, -2.5],
    camberRearRange: [-5.0, -1.5],
    camberStep: 0.1,
    casterArr: [
      7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7,
      8.8, 8.9, 9, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10, 10.1, 10.2,
      10.3,
    ],
    eCUMapRange: [1, 1],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 65],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [0, 1, 2, 3],
    wheelRatesRear: [0, 1, 2, 3],
    bumpStopRateRange: [1000, 1000],
    bumpStopRateStep: 1,
    bumpStopWindowFrontRange: [0, 120],
    bumpStopWindowRearRange: [0, 200],
    bumpStopWindowStep: 1,
    preloadRange: [0, 0],
    preloadStep: 10,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 12],
    reboundFastRange: [0, 12],
    damperStep: 1,
    rideHeightFrontRange: [60, 90],
    rideHeightRearRange: [80, 120],
    rideHeightFrontRange_n24h: [60, 90],
    rideHeightRearRange_n24h: [80, 120],
    rideHeightStep: 1,
    rearWingRange: [1, 10],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  porsche_992_gt3_cup: {
    fullName: "Porsche 992 GT3 Cup",
    year: 2021,
    category: "cup",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-5.0, -2.5],
    camberRearRange: [-4.4, -1.5],
    camberStep: 0.1,
    casterArr: [14.8],
    eCUMapRange: [1, 2],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 65],
    brakeBiasStep: 0.2,
    steerRatioRange: [11, 17],
    steerRatioStep: 1,
    wheelRatesFront: [1, 1],
    wheelRatesRear: [1, 1],
    bumpStopRateRange: [1, 1],
    bumpStopRateStep: 1,
    bumpStopWindowFrontRange: [1, 1],
    bumpStopWindowRearRange: [1, 1],
    bumpStopWindowStep: 1,
    bumpSlowRange: [1, 1],
    reboundSlowRange: [1, 1],
    bumpFastRange: [1, 1],
    reboundFastRange: [1, 1],
    damperStep: 1,
    preloadRange: [1, 1],
    preloadStep: 10,
    rideHeightFrontRange: [72, 90],
    rideHeightRearRange: [106, 125],
    rideHeightFrontRange_n24h: [72, 90],
    rideHeightRearRange_n24h: [106, 125],
    rideHeightStep: 1,
    rearWingRange: [1, 11],
    rearWingStep: 1,
    splitterRange: [0, 6],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  // TODO: Add Huracan ST
  lamborghini_huracan_st_evo2: {
    fullName: "Lamborghini Huracan ST EVO2",
    year: 2021,
    category: "st",
    tyrePressureRange: [20.3, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-3.0, -0.5],
    camberRearRange: [-3.5, -0.5],
    camberStep: 0.1,
    casterArr: [
      10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
      13.1, 13.3, 13.5, 13.7, 13.9, 14.1, 14.3, 14.5, 14.7, 14.9, 15.1, 15.3,
      15.5, 15.7, 15.9, 16.1, 16.3, 16.5, 16.7, 16.8, 17.0, 17.2, 17.4,
    ],
    eCUMapRange: [1, 8],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [50, 71.0],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 15],
    steerRatioStep: 1,
    wheelRatesFront: [121000, 144000, 167000, 190000, 201000, 212000],
    wheelRatesRear: [117000, 136000, 154000, 164000, 173000, 191000],
    bumpStopRateRange: [300, 600],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 30],
    bumpStopWindowRearRange: [0, 72],
    bumpStopWindowStep: 1,
    preloadRange: [20, 300],
    preloadStep: 10,
    bumpSlowRange: [1, 36],
    reboundSlowRange: [1, 36],
    bumpFastRange: [1, 1],
    reboundFastRange: [1, 1],
    damperStep: 1,
    rideHeightFrontRange: [54, 80],
    rideHeightRearRange: [54, 90],
    rideHeightFrontRange_n24h: [65, 100],
    rideHeightRearRange_n24h: [65, 110],
    rideHeightStep: 1,
    rearWingRange: [0, 19],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  ferrari_488_challenge_evo: {
    fullName: "Ferrari 488 Challenge Evo",
    year: 2020,
    category: "chl",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.4, 0.4],
    toeRearRange: [-0.4, 0.4],
    toeStep: 0.01,
    camberFrontRange: [-4.4, -1.5],
    camberRearRange: [-3.3, -1.0],
    camberStep: 0.1,
    casterArr: [
      5.0, 5.1, 5.3, 5.5, 5.6, 5.8, 6.0, 6.1, 6.3, 6.5, 6.6, 6.8, 7.0, 7.1, 7.3,
      7.5, 7.6, 7.8, 8.0, 8.1, 8.3, 8.5, 8.6, 8.8, 9.0, 9.1, 9.3, 9.5, 9.6, 9.8,
      9.9, 10.1, 10.3, 10.4, 10.6, 10.8, 10.9, 11.1, 11.2, 11.4, 11.6, 11.7,
      11.9, 12.1, 12.2, 12.4, 12.5, 12.7, 12.9, 13.0, 13.2, 13.3, 13.5, 13.7,
      13.8, 14.0, 14.1, 14.3, 14.4, 14.6, 14.8, 14.9, 15.1, 15.2, 15.4, 15.5,
      15.7, 15.9, 16.0, 16.2, 16.3, 16.5, 16.6, 16.8, 16.9, 17.1, 17.3, 17.4,
      17.6, 17.7, 17.9, 18.0, 18.2, 18.3, 18.5, 18.6, 18.8, 18.9, 19.1, 19.2,
      19.4, 19.5, 19.7, 19.8, 20.0, 20.1, 20.3, 20.4, 20.6,
    ],
    eCUMapRange: [1, 1],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [51, 51],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [151000, 163800],
    wheelRatesRear: [141000, 155000],
    bumpStopRateRange: [0, 0],
    bumpStopRateStep: 0,
    bumpStopWindowFrontRange: [0, 0],
    bumpStopWindowRearRange: [0, 0],
    bumpStopWindowStep: 0,
    preloadRange: [0, 0],
    preloadStep: 0,
    bumpSlowRange: [0, 11],
    reboundSlowRange: [0, 11],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 0],
    damperStep: 1,
    rideHeightFrontRange: [59, 70],
    rideHeightRearRange: [103, 110],
    rideHeightFrontRange_n24h: [59, 70],
    rideHeightRearRange_n24h: [103, 110],
    rideHeightStep: 1,
    rearWingRange: [0, 6],
    rearWingStep: 1,
    splitterRange: [1, 2],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
  bmw_m2_cs_racing: {
    category: "tcx",
    year: 2020,
    fullName: "BMW M2 CS Racing",
    tyrePressureRange: [17, 35],
    tyrePressureStep: 0.1,
    toeFrontRange: [-0.2, 0.2],
    toeRearRange: [0.0, 0.31],
    toeStep: 0.01,
    camberFrontRange: [-5.0, -3.0],
    camberRearRange: [-3.5, -2.0],
    camberStep: 0.1,
    casterArr: [8.5],
    eCUMapRange: [1, 1],
    eCUMapStep: 1,
    brakeTorqueRange: [80, 100],
    brakeTorqueStep: 1,
    brakeBiasRange: [56, 56],
    brakeBiasStep: 0.2,
    steerRatioRange: [10, 17],
    steerRatioStep: 1,
    wheelRatesFront: [162000, 180000, 198000],
    wheelRatesRear: [103000, 117000, 131000],
    bumpStopRateRange: [300, 1000],
    bumpStopRateStep: 100,
    bumpStopWindowFrontRange: [0, 48],
    bumpStopWindowRearRange: [0, 40],
    bumpStopWindowStep: 1,
    preloadRange: [20, 20],
    preloadStep: 10,
    bumpSlowRange: [1, 20],
    reboundSlowRange: [1, 20],
    bumpFastRange: [0, 0],
    reboundFastRange: [0, 0],
    damperStep: 1,
    rideHeightFrontRange: [125, 140],
    rideHeightRearRange: [125, 140],
    rideHeightFrontRange_n24h: [125, 140],
    rideHeightRearRange_n24h: [125, 140],
    rideHeightStep: 1,
    rearWingRange: [1, 5],
    rearWingStep: 1,
    splitterRange: [0, 0],
    splitterStep: 1,
    brakeDuctRange: [0, 6],
    brakeDuctStep: 1,
  },
};

export const supportedCars = Object.keys(cars).map((key) => {
  const { fullName, year, category } = cars[key];
  return {
    name: key,
    fullName,
    year,
    category,
  };
});

// /* Prepare Caster */
// const getSampleRange = (start, end, step) => {
//   let range = [];
//   for (let i = start; i <= end; i = i + step) {
//     range.push(Math.round((i + Number.EPSILON) * 100) / 100);
//   }
//   return range;
// };

// console.log("Porsche ", getSampleRange(9.2, 16.4, 0.2));
