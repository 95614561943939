import React from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { Layout, Row, Col, Grid, Card } from "antd";
import {
  MobileOutlined,
  TabletOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import Homepage from "./containers/Homepage";
import SendSetupsDoc from "./containers/SendSetupsDoc";
import Insights from "./containers/Insights";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsAndConditions from "./containers/TermsAndConditions";
import AccCompLogo from "./assets/acccomplogo";
import LukeAddisonLogo from "./assets/sponsors/luke_addison_coaching.png";
import FlowRacersLogo from "./assets/sponsors/Flow-Racers.png";
import CDALogo1 from "./assets/sponsors/cda-banner-sub-1.png";
import DeltaLogo2 from "./assets/sponsors/cda-banner-delta-2.png";
import "antd/dist/antd.css";
import "./App.css";

const { Header, Content, Footer } = Layout;

const { useBreakpoint } = Grid;

const App = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  const currRes = Object.entries(screens).filter((screen) => !!screen[1]);

  const isXS = currRes.find((item) => item[0] === "xs");
  const isSM = currRes.find((item) => item[0] === "sm");
  const isXL = currRes.find((item) => item[0] === "xl");
  let sponsorWidth;
  if (isXS) {
    sponsorWidth = "100%";
  } else if (isSM && !isXL) {
    sponsorWidth = "50%";
  } else if (isXL) {
    sponsorWidth = "50%";
  } else {
    sponsorWidth = "50%";
  }

  const donatorStyle = {
    textAlign: "center",
    width: sponsorWidth,
    padding: 16,
    borderRadius: "50%",
  };

  const exclusiveSponsorStyle = {
    textAlign: "center",
    width: sponsorWidth,
    padding: 16,
  };

  const hideExclusiveSponsor = [
    "/termsandconditions",
    "/privacypolicy",
    "/becomeabacker",
  ];

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          background: "#292c30",
          zIndex: 99,
          width: "100%",
          padding: 0,
        }}
      >
        <div
          className="logo"
          style={{
            display: "flex",
            padding: "0 16px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AccCompLogo width={32} height={32} style={{ marginRight: 8 }} />
            <h1 style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
              ACCSetupComparator <span className="version">v2.3</span>
            </h1>
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="compatibility"
          >
            <span
              style={{
                color: "white",
                marginRight: 8,
              }}
            >
              Compatibility:{" "}
            </span>
            <MobileOutlined
              style={{
                fontSize: 18,
                color: "white",
                padding: 0,
              }}
            />
            <TabletOutlined
              style={{
                fontSize: 18,
                color: "white",
                padding: 0,
                marginLeft: 2,
              }}
            />
            <DesktopOutlined
              alt="Desktop"
              height={32}
              width={32}
              style={{
                fontSize: 21,
                color: "white",
                padding: 0,
                marginLeft: 4,
              }}
            />
          </div>
        </div>
      </Header>
      <Content style={{ marginTop: 64, marginBottom: 16 }}>
        <Routes>
          <Route path="/" element={<Homepage />} exact />
          <Route path="/sendsetups" element={<SendSetupsDoc />} exact />
          <Route path="/insights" element={<Insights />} exact />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} exact />
          <Route
            path="/termsandconditions"
            element={<TermsAndConditions />}
            exact
          />
        </Routes>
        {hideExclusiveSponsor.includes(location.pathname) ? null : (
          <Row
            gutter={[16, 16]}
            style={{
              marginBottom: 16,
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            <Col xl={24}>
              <Card title="Exclusive Sponsor">
                <Card.Grid style={exclusiveSponsorStyle}>
                  <a
                    href="https://coachdaveacademy.com/product-category/car-setups"
                    target="_blank"
                  >
                    <img
                      src={CDALogo1}
                      alt="CoachDave Setups"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </a>
                </Card.Grid>
                <Card.Grid style={exclusiveSponsorStyle}>
                  <a href="https://coachdaveacademy.com/delta" target="_blank">
                    <img
                      src={DeltaLogo2}
                      alt="CoachDave Delta"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </a>
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        )}
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16,
            marginLeft: 12,
            marginRight: 12,
          }}
        >
          <Col xs={24} sm={24} xl={8} style={{ width: "100%" }}>
            <Card title="Backers" style={{ minHeight: 200, maxHeight: 100 }}>
              <div style={{ display: "flex" }} className="backers__section">
                <div className="backer">
                  <a href="https://lukeaddison-racing.com" target="_blank">
                    <img src={LukeAddisonLogo} />
                  </a>
                </div>

                <div className="backer" style={{ background: "#f7f7f7" }}>
                  <a href="https://flowracers.com" target="_blank">
                    <img src={FlowRacersLogo} />
                  </a>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={8}>
            <Card
              title={"Contact Info"}
              bordered={true}
              style={{
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                minHeight: 200,
              }}
            >
              <div>
                <ul>
                  <li>Contact - rajavn6789@gmail.com</li>

                  <li>
                    Viewing setups from your store -
                    <a href="/sendsetups"> send setups </a>
                  </li>

                  <li>
                    Read our -
                    <Link style={{ marginLeft: 4 }} to={"/privacypolicy"}>
                      PrivacyPolicy
                    </Link>{" "}
                  </li>

                  <li>
                    Read our -
                    <Link style={{ marginLeft: 4 }} to={"/termsandconditions"}>
                      TermsAndConditions
                    </Link>
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
          {/* <Col xs={24} sm={24} xl={8}>
            <Card
              title={"Lapinsights.io"}
              style={{ minHeight: 200, maxHeight: 200 }}
            >
              <p>
                Currently working on{" "}
                <a href="https://lapinsights.io" target={"_blank"}>
                  Lapinsights.io
                </a>{" "}
                tool which is an interactive web based lap analysis tool. It
                helps to quickly visualize, analyse and compare lap telemetry
                data. I am the only developer and If you would like to help in
                beta testing you are most welcome!
              </p>
            </Card>
          </Col> */}

          <Col xs={24} sm={24} xl={8}>
            <Card
              title={"Version History"}
              bordered={true}
              style={{
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                minHeight: 200,
              }}
            >
              <ul
                style={{
                  maxHeight: 80,
                  overflowY: "auto",
                }}
              >
                <li>v2.2 - GT2 cars support.</li>
                <li>v2.2 - nurb24h track support.</li>
                <li>
                  v2.0 - Revamped UI to match with ACC setup screen for better
                  experience.
                </li>
                <li>v1.0 - Show delta during comparison.</li>
                <li>v0.8 - Performance improvements.</li>
                <li>v0.5 - Added gt4 cars.</li>
                <li>v0.1 - Initial lauch.</li>
              </ul>
            </Card>
          </Col>
        </Row>
        {/* {hideBacker.includes(location.pathname) ? null : (
          <Row
            gutter={[16, 16]}
            style={{
              marginBottom: 16,
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            <Col xl={24} style={{ width: "100%" }}>
              <Card
                title="Our Backers"
                style={{ minHeight: 250, maxHeight: 250 }}
              >
                <div style={{ display: "flex" }} className="backers__section">
                  <div className="backer">
                    <a href="https://lukeaddison-racing.com" target="_blank">
                      <img src={LukeAddisonLogo} />
                    </a>
                  </div>

                  <div className="backer" style={{ background: "#f7f7f7" }}>
                    <a href="https://flowracers.com" target="_blank">
                      <img src={FlowRacersLogo} />
                    </a>
                  </div>

                  {[...Array(1).keys()].map((index) => {
                    return (
                      <div className="backer dummy">
                        <Link to={"/becomeabacker"}>
                          <span>Become a Backer :-)</span>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </Col>
          </Row>
        )} */}
      </Content>
      <Footer
        style={{
          padding: 0,
          height: 64,
          background: "#292c30",
          color: "white",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <div style={{ margin: 8 }}>
            ©2024 ACCSetupComparator | Developed by Raja
          </div>
        </div>
      </Footer>
    </Layout>
  );
};

export default App;
