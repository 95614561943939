import React from "react";
import { Card } from "antd";
import Tyre from "./cards/Tyre";
import Electronics from "./cards/Electronics";
import Mechanical from "./cards/Mechanical";
import Dampers from "./cards/Dampers";
import Aero from "./cards/Aero";

const ComparisonSection = ({ dynamicData, mode, setup2 }) => {
  const tyreData = dynamicData.find((obj) => obj.key === "tyres");
  const electronicsData = dynamicData.find((obj) => obj.key === "electronics");
  const mechanicalData = dynamicData.find((obj) => obj.key === "mechanical");
  const dampersData = dynamicData.find((obj) => obj.key === "dampers");
  const aeroData = dynamicData.find((obj) => obj.key === "aeroBalance");

  return (
    <>
      <Card
        className="setup-innerpage-card"
        bordered={true}
        style={{
          margin: "8px 16px",
          minHeight: "50vh",
          boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
        }}
      >
        <Tyre data={tyreData} mode={mode} isSetup2Loaded={setup2} />
        <Electronics
          data={electronicsData}
          mode={mode}
          isSetup2Loaded={setup2}
        />
        <Mechanical data={mechanicalData} mode={mode} isSetup2Loaded={setup2} />
        <Dampers data={dampersData} mode={mode} isSetup2Loaded={setup2} />
        <Aero data={aeroData} mode={mode} isSetup2Loaded={setup2} />
      </Card>
    </>
  );
};

export default ComparisonSection;
