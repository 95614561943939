import React from "react";
import { Card, Tag } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { supportedCars } from "../../utils/config/cars";

const SupportedCarsSection = (props) => {
  const { carName, style } = props;

  let gt3Cars = [];
  let gt2Cars = [];
  let cupCars = [];
  let stCars = [];
  let chlCars = [];
  let tcxCars = [];
  let gt4Cars = [];

  supportedCars.forEach((car) => {
    const { category } = car;
    if (category === "gt2") {
      gt2Cars.push(car);
    } else if (category === "gt3") {
      gt3Cars.push(car);
    } else if (category === "cup") {
      cupCars.push(car);
    } else if (category === "st") {
      stCars.push(car);
    } else if (category === "chl") {
      chlCars.push(car);
    } else if (category === "tcx") {
      tcxCars.push(car);
    } else if (category === "gt4") {
      gt4Cars.push(car);
    }
  });

  const miscCars = [...gt4Cars, ...cupCars, ...stCars, ...chlCars, ...tcxCars];

  const renderTags = (cars) => {
    let element;

    const sortedCars = cars.sort((a, b) => b.year - a.year);

    if (sortedCars && sortedCars.length > 0) {
      element = sortedCars.map((car) => {
        let color;
        let backgroundColor;
        let icon;
        if (car.name === carName) {
          color = "white";
          backgroundColor = "black";
          icon = <EyeOutlined />;
        } else {
          color = "#747474";
          backgroundColor = "#9f9f9f";
          icon = <CheckCircleOutlined />;
        }

        return (
          <Tag
            icon={icon}
            key={`car_${car.name}`}
            style={{
              borderRadius: 10,
              margin: 4,
              padding: "2px 7px",
              background: backgroundColor,
            }}
            color={color}
          >
            {`${car.fullName} ${car.year}`}
          </Tag>
        );
      });
    } else {
      element = null;
    }
    return element;
  };

  return (
    <>
      <Card
        className="supported-cars-main-card"
        title={`SUPPORTED CARS`}
        bordered={true}
        style={{
          boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
          ...style,
        }}
        bodyStyle={{
          maxHeight: 250,
          overflowY: "auto",
        }}
      >
        <Card type="inner" bodyStyle={{ padding: 8 }}>
          <div style={{ padding: "0px 8px" }}> {renderTags(gt3Cars)}</div>
        </Card>
        <Card type="inner" bodyStyle={{ padding: 8 }}>
          <div style={{ padding: "0px 8px" }}>{renderTags(gt2Cars)}</div>
        </Card>
        <Card type="inner" bodyStyle={{ padding: 8 }}>
          <div style={{ padding: "0px 8px" }}>{renderTags(miscCars)}</div>
        </Card>
      </Card>
    </>
  );
};

export default SupportedCarsSection;
