import React from "react";
import { Card, Row, Col } from "antd";
import StyledButton from "../../components/StyledButton";

const SendSetupsDoc = () => {
  const host = "https://www.accsetupcomparator.com";

  const handleSetupSendSingleMode = () => {
    const setup1Data = require(`../../utils/sample/setups/aggressive.json`);

    const setup1DataString = JSON.stringify(setup1Data);

    // single mode
    window.open(
      `${host}?source=yourstorename&mode=single&filename1="aggressive"&setup1=${encodeURIComponent(
        setup1DataString
      )}`,
      "_blank"
    );
  };

  const handleSetupSendCompareMode = () => {
    const setup1Data = require(`../../utils/sample/setups/aggressive.json`);
    const setup2Data = require(`../../utils/sample/setups/wet.json`);

    const setup1DataString = JSON.stringify(setup1Data);
    const setup2DataString = JSON.stringify(setup2Data);

    // compare mode
    window.open(
      `${host}?source=yourstorename&mode=compare&filename1="aggressive"&filename2="wet"&setup1=${encodeURIComponent(
        setup1DataString
      )}&setup2=${encodeURIComponent(setup2DataString)}`,
      "_blank"
    );
  };

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginTop: 16,
          marginBottom: 16,
          marginLeft: 12,
          marginRight: 12,
        }}
      >
        <Col xs={24} sm={24} xl={24}>
          <Card
            title={"Sending Setups"}
            className="innerpage-card"
            bordered={true}
            style={{
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            }}
          >
            <div style={{ marginBottom: 16 }}>
              Sending setups to AccSetupComparator is easier, no additional api
              calls required. All you have to do is redirect to the tool with
              setupfile and your store details in the url query parameters.
              please refer below for more details.
            </div>

            <Card type="inner" title="Single mode" style={{ marginTop: 8 }}>
              <div style={{ display: "flex" }}>
                <pre>
                  {`${host}?source=yourstorename&mode=single&filename1="aggressive"&setup1=urlencodedsetupstring`}
                </pre>
              </div>
              <StyledButton onClick={handleSetupSendSingleMode} type="primary">
                Send single setup
              </StyledButton>
            </Card>
            <Card type="inner" title="Comparison Mode" style={{ marginTop: 8 }}>
              <div style={{ display: "flex" }}>
                <pre>
                  {`https://www.accsetupcomparator.com?source=yourstorename&mode=compare&filename1="aggressive"&filename2="wet"&setup1=urlencodedsetup1string&setup2=urlencodedsetup2string`}
                </pre>
              </div>
              <StyledButton onClick={handleSetupSendCompareMode} type="primary">
                Send two setups
              </StyledButton>
            </Card>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SendSetupsDoc;
