import React from "react";

const SetupIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      {...props}
    >
      <circle cx="256.001" cy="255.996" r="255.996" fill="#292c30"></circle>
      <path
        d="M250.319 511.925L128.485 390.091l101.791-110.562-69.534-73.066 88.01-64.208 51.75 50.617 68.211-68.301 143.021 143.021c-6.06 136.004-118.227 244.409-255.727 244.409-1.902 0-3.794-.029-5.686-.072l-.002-.004z"
        color="#262626"
      ></path>
      <path
        fill="#EBECEC"
        d="M334.235 163.341l-94.864 111.358-12.105-10.519 94.864-111.354 1.989-9.836 31.591-29.714 13.001 11.297-25.017 35.429-9.459 3.345v-.006z"
      ></path>
      <path
        fill="#E3E3E3"
        d="M334.235 163.341l-92.894 109.045-4.726-6.743 125.593-146.721 6.499 5.65L343.69 160l-9.459 3.341h.004z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M235.958 283.172l6.053-6.966a4.451 4.451 0 00-.439-6.262l-9.901-8.603a4.454 4.454 0 00-6.262.439l-6.053 6.966c-6.934-4.798-16.594-3.704-22.248 2.802l-77.187 88.833c-6.064 6.981-5.319 17.648 1.662 23.711l13.81 11.998c6.977 6.064 17.648 5.312 23.711-1.665l77.187-88.833c5.65-6.506 5.387-16.224-.331-22.421l-.002.001z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M235.958 283.172l6.057-6.966a4.451 4.451 0 00-.442-6.262l-4.949-4.302-108.135 124.449 6.909 6.003c6.98 6.064 17.648 5.312 23.711-1.665l77.187-88.833c5.653-6.506 5.391-16.224-.331-22.421l-.007-.003z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M331.253 311.613c16.109-2.867 33.314 3.381 43.87 17.565 11.077 14.889 11.678 34.289 3.061 49.214l-20.802-27.959-28.699 21.356 20.474 27.517c-16.411 3.348-34.159-2.863-44.945-17.361-10.555-14.185-11.599-32.466-4.219-47.071L210.92 222.038c-20.316 3.923-42.169-3.848-55.501-21.769-12.552-16.871-14.321-38.392-6.474-56.151l32.315 43.427 43.006-31.995-32.315-43.43c19.267-2.409 39.371 5.463 51.923 22.338 13.336 17.921 14.501 41.082 4.902 59.41l82.481 117.745h-.004z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M331.253 311.616c16.109-2.87 33.314 3.381 43.866 17.565 11.081 14.889 11.678 34.289 3.064 49.218l-20.806-27.962-13.839 10.304-140.779-189.189 21.503-15.997-32.315-43.43c19.259-2.413 39.367 5.463 51.923 22.334 13.336 17.925 14.498 41.086 4.906 59.41l82.481 117.752-.004-.005z"
      ></path>
    </svg>
  );
};

export default SetupIcon;
