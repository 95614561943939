import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { message, Row, Col, Card } from "antd";
import ComparisonSection from "../../components/ComparisonSection";
import SetupsUploadSection from "../../components/SetupsUploadSection";
import SupportedCarsSection from "../../components/SupportedCarsSection";
import { initialData } from "../../utils/config/initialData";
import StyledButton from "../../components/StyledButton";
import { supportedCars } from "../../utils/config/cars";
import { getMappedRangeValuesByCar } from "../../utils/functions";
import "antd/dist/antd.css";
import "../../App.css";

const prepareSetupValues = (setup) => {
  return {
    tyreCompound: setup?.basicSetup?.tyres?.tyreCompound || 0,
    tyrePressure: setup?.basicSetup?.tyres?.tyrePressure || [0, 0, 0, 0],
    toe: setup?.basicSetup?.alignment?.toe || [0, 0, 0, 0],
    camber: setup?.basicSetup?.alignment?.camber || [0, 0, 0, 0],
    casterLF: setup?.basicSetup?.alignment?.casterLF || 0,
    casterRF: setup?.basicSetup?.alignment?.casterRF || 0,
    tC1: setup?.basicSetup?.electronics?.tC1 || 0,
    tC2: setup?.basicSetup?.electronics?.tC2 || 0,
    abs: setup?.basicSetup?.electronics?.abs || 0,
    eCUMap: setup?.basicSetup?.electronics?.eCUMap || 0,
    telemetryLaps: setup?.basicSetup?.electronics?.telemetryLaps || 0,

    fuel: setup?.basicSetup?.strategy?.fuel || 0,
    tyreSet: setup?.basicSetup?.strategy?.tyreSet || 0,
    frontBrakePadCompound:
      setup?.basicSetup?.strategy?.frontBrakePadCompound || 0,
    rearBrakePadCompound:
      setup?.basicSetup?.strategy?.rearBrakePadCompound || 0,
    fuelPerLap: setup?.basicSetup?.strategy?.fuelPerLap || 0,

    aRBFront: setup?.advancedSetup?.mechanicalBalance?.aRBFront || 0,
    aRBRear: setup?.advancedSetup?.mechanicalBalance?.aRBRear || 0,
    brakeTorque: setup?.advancedSetup?.mechanicalBalance?.brakeTorque || 0,
    brakeBias: setup?.advancedSetup?.mechanicalBalance?.brakeBias || 0,
    steerRatio: setup?.basicSetup?.alignment?.steerRatio || 0,
    wheelRate: setup?.advancedSetup?.mechanicalBalance?.wheelRate || [
      0, 0, 0, 0,
    ],
    bumpStopRateUp: setup?.advancedSetup?.mechanicalBalance?.bumpStopRateUp || [
      0, 0, 0, 0,
    ],
    bumpStopWindow: setup?.advancedSetup?.mechanicalBalance?.bumpStopWindow || [
      0, 0, 0, 0,
    ],
    preload: setup?.advancedSetup?.drivetrain?.preload,
    bumpSlow: setup?.advancedSetup?.dampers?.bumpSlow || [0, 0, 0, 0],
    bumpFast: setup?.advancedSetup?.dampers?.bumpFast || [0, 0, 0, 0],
    reboundSlow: setup?.advancedSetup?.dampers?.reboundSlow || [0, 0, 0, 0],
    reboundFast: setup?.advancedSetup?.dampers?.reboundFast || [0, 0, 0, 0],
    rideHeightFront: setup?.advancedSetup?.aeroBalance?.rideHeight[0] || 0,
    rideHeightRear: setup?.advancedSetup?.aeroBalance?.rideHeight[2] || 0,
    rearWing: setup?.advancedSetup?.aeroBalance?.rearWing || 0,
    splitter: setup?.advancedSetup?.aeroBalance?.splitter || 0,
    brakeDuct: setup?.advancedSetup?.aeroBalance?.brakeDuct || [0, 0],
    trackBopType: setup.trackBopType,
  };
};

const HomePage = (props) => {
  const [mode, setMode] = useState("compare");
  const [setup1, setSetup1] = useState(false);
  const [setup2, setSetup2] = useState(false);
  const [carName, setCarName] = useState(false);
  const [setup1File, setSetup1File] = useState(null);
  const [setup2File, setSetup2File] = useState(null);
  const [dynamicData, setDynamicData] = useState(initialData);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const source = searchParams.get("source");
    if (source) {
      const mode = searchParams.get("mode");
      if (mode === "single") {
        setMode("single");

        const fileName1 = searchParams.get("filename1");
        const setup1Str = searchParams.get("setup1");

        let setup1Data;
        try {
          setup1Data = JSON.parse(setup1Str);
          if (setup1Data.carName) {
            const isCarSupported = supportedCars.find(
              (o) => o.name === setup1Data.carName
            );
            if (isCarSupported) {
              setCarName(setup1Data.carName);

              const file1 = [
                {
                  uid: "111111",
                  type: "sample",
                  name: fileName1.replace(/["]/g, "") || "setup1",
                },
              ];
              setSetup1File(file1[0]);
              setSetup2File(file1[0]);

              setSetup1(setup1Data);
              setSetup2(setup1Data);
              message.success(`Setup from ${source} loaded successfully.`);
            } else {
              setSetup1File(null);
              setSetup2File(null);

              setSetup1(false);
              setSetup2(false);
              message.error(
                `Sorry ${setup1Data.carName} is not supported yet, please check back after few days.`
              );
            }
          } else {
            message.error(`Invalid or corrupted setup file`);
          }
        } catch (e) {
          message.error(`Invalid or corrupted setup file`);
        }
      } else if (mode === "compare") {
        setMode("compare");
        const fileName1 = searchParams.get("filename1");
        const fileName2 = searchParams.get("filename2");
        const setup1Str = searchParams.get("setup1");
        const setup2Str = searchParams.get("setup2");

        let setup1Data;
        let setup2Data;
        try {
          setup1Data = JSON.parse(setup1Str);
          setup2Data = JSON.parse(setup2Str);

          if (setup1Data.carName) {
            const isCarSupported = supportedCars.find(
              (o) => o.name === setup1Data.carName
            );
            if (isCarSupported) {
              setCarName(setup1Data.carName);

              const file1 = [
                {
                  uid: "111111",
                  type: "sample",
                  name: fileName1.replace(/["]/g, "") || "setup1",
                },
              ];

              const file2 = [
                {
                  uid: "222222",
                  type: "sample",
                  name: fileName2.replace(/["]/g, "") || "setup2",
                },
              ];

              setSetup1File(file1[0]);
              setSetup2File(file2[0]);

              setSetup1(setup1Data);
              setSetup2(setup2Data);

              message.success(
                `Setups from ${source} loaded successfully in comparison view.`
              );
            } else {
              setSetup1File(null);
              setSetup2File(null);

              setSetup1(false);
              setSetup2(false);
              message.error(
                `Sorry ${setup1Data.carName} is not supported yet, please check back after few days.`
              );
            }
          } else {
            message.error(`Invalid or corrupted setup file`);
          }
        } catch (e) {
          message.error(`Invalid or corrupted setup file`);
        }
      } else {
        message.error(
          `${mode} is missing in the params should be a single or compare.`
        );
      }
    }
  }, []);

  useEffect(() => {
    if (setup1File && setup1File.type !== "sample") {
      const { originFileObj } = setup1File;
      let reader = new FileReader();
      reader.readAsText(originFileObj);
      reader.onload = function () {
        try {
          const data = JSON.parse(reader.result);
          if (data.carName) {
            const isCarSupported = supportedCars.find(
              (o) => o.name === data.carName
            );
            if (isCarSupported) {
              setCarName(data.carName);
              setSetup1(data);
            } else {
              setSetup1(false);
              message.error(
                `Sorry ${data.carName} is not supported yet, please check back after few days.`,
                2,
                () => {
                  setSetup1(false);
                  setSetup1File(null);

                  setSetup2(false);
                  setSetup2File(null);
                }
              );
            }
          } else {
            message.error(`Invalid or corrupted setup file`);
          }
        } catch (e) {
          message.error(`Invalid or corrupted setup file`);
        }
      };
    }
  }, [setup1File]);

  useEffect(() => {
    if (setup2File && setup2File.type !== "sample") {
      const { originFileObj } = setup2File;
      let reader = new FileReader();
      reader.readAsText(originFileObj);
      reader.onload = function () {
        try {
          const data = JSON.parse(reader.result);
          if (data.carName) {
            const isCarSupported = supportedCars.find(
              (o) => o.name === data.carName
            );
            if (isCarSupported) {
              setSetup2(data);
            } else {
              message.error(
                `Sorry ${data.carName} is not supported yet, please check back after few days.`,
                2,
                () => {
                  setSetup2(false);
                  setSetup2File(null);

                  setSetup1(false);
                  setSetup1File(null);
                }
              );
            }
          } else {
            message.error(`Invalid or corrupted setup file`);
          }
        } catch (e) {
          message.error(`Invalid or corrupted setup file`);
        }
      };
    }
  }, [setup2File]);

  useEffect(() => {
    let dynamicData;

    let arbOffset;
    if (carName && carName === "honda_nsx_gt3_evo") {
      arbOffset = 1;
    } else {
      arbOffset = 0;
    }

    if (setup1 || setup2) {
      // set defaults
      const updatedSetup = {
        setup1: prepareSetupValues(setup1),
        setup2: prepareSetupValues(setup2),
      };

      const setup1RangeObj = getMappedRangeValuesByCar(
        carName,
        updatedSetup.setup1
      );

      const setup2RangeObj = getMappedRangeValuesByCar(
        carName,
        updatedSetup.setup2
      );

      dynamicData = [
        {
          key: "tyres",
          name: "Tyres",
          setup1: "-",
          setup2: "-",
          children: [
            {
              key: "tyreCompound",
              name: "tyre",
              setup1: setup1 ? setup1RangeObj.tyreCompound : 0,
              setup2: setup2 ? setup2RangeObj.tyreCompound : 0,
            },
            {
              key: "tyrePressure",
              name: "psi (°C)",
              setup1: setup1 ? setup1RangeObj.tyrePressure : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.tyrePressure : [0, 0, 0, 0],
            },
            {
              key: "toe",
              name: "toe (°)",
              setup1: setup1 ? setup1RangeObj.toe : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.toe : [0, 0, 0, 0],
            },
            {
              key: "camber",
              name: "camber (°)",
              setup1: setup1 ? setup1RangeObj.camber : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.camber : [0, 0, 0, 0],
            },
            {
              key: "caster",
              name: "caster (°)",
              setup1: setup1
                ? [setup1RangeObj.casterLF, setup1RangeObj.casterRF]
                : [0, 0],
              setup2: setup2
                ? [setup2RangeObj.casterLF, setup2RangeObj.casterRF]
                : [0, 0],
            },
          ],
        },
        {
          key: "electronics",
          name: "Electronics",
          setup1: "-",
          setup2: "-",
          children: [
            {
              key: "tC1",
              name: "TC1",
              setup1: setup1 ? updatedSetup.setup1.tC1 : 0,
              setup2: setup2 ? updatedSetup.setup2.tC1 : 0,
            },
            {
              key: "tC2",
              name: "TC2",
              setup1: setup1 ? updatedSetup.setup1.tC2 : 0,
              setup2: setup2 ? updatedSetup.setup2.tC2 : 0,
            },
            {
              key: "abs",
              name: "ABS",
              setup1: setup1 ? updatedSetup.setup1.abs : 0,
              setup2: setup2 ? updatedSetup.setup2.abs : 0,
            },
            {
              key: "ecuMap",
              name: "ECUMap", // move to range
              setup1: setup1 ? setup1RangeObj.eCUMap : 0,
              setup2: setup2 ? setup2RangeObj.eCUMap : 0,
            },
            {
              key: "telemetryLaps",
              name: "telemetry laps",
              setup1: setup1 ? updatedSetup.setup1.telemetryLaps : 0,
              setup2: setup2 ? updatedSetup.setup2.telemetryLaps : 0,
            },
          ],
        },
        {
          key: "fuelandstrategy",
          name: "Fuel & Strategy",
          setup1: "-",
          setup2: "-",
          children: [
            {
              key: "fuel",
              name: "fuel",
              setup1: setup1 ? setup1RangeObj.fuel : 0,
              setup2: setup2 ? setup2RangeObj.fuel : 0,
            },
            {
              key: "tyreSet",
              name: "tyre set",
              setup1: setup1 ? setup1RangeObj.tyreSet : 0,
              setup2: setup2 ? setup2RangeObj.tyreSet : 0,
            },
            {
              key: "frontBrakePadCompound",
              name: "front brakes",
              setup1: setup1 ? setup1RangeObj.frontBrakePadCompound : 0,
              setup2: setup2 ? setup2RangeObj.frontBrakePadCompound : 0,
            },
            {
              key: "rearBrakePadCompound",
              name: "rear brakes",
              setup1: setup1 ? setup1RangeObj.rearBrakePadCompound : 0,
              setup2: setup2 ? setup2RangeObj.rearBrakePadCompound : 0,
            },
            {
              key: "fuelPerLap",
              name: "fuel per lap",
              setup1: setup1
                ? Math.round(updatedSetup.setup1.fuelPerLap * 100) / 100
                : 0,
              setup2: setup2
                ? Math.round(updatedSetup.setup2.fuelPerLap * 100) / 100
                : 0,
            },
          ],
        },
        {
          key: "mechanical",
          name: "Mechanical Grip",
          setup1: "-",
          setup2: "-",
          children: [
            {
              key: "aRB",
              name: "antiroll bar",
              setup1: setup1
                ? [
                    updatedSetup.setup1.aRBFront + arbOffset,
                    updatedSetup.setup1.aRBRear + arbOffset,
                  ]
                : [0, 0],
              setup2: setup2
                ? [
                    updatedSetup.setup2.aRBFront + arbOffset,
                    updatedSetup.setup2.aRBRear + arbOffset,
                  ]
                : [0, 0],
            },
            {
              key: "brakeTorque",
              name: "brake power (%)",
              setup1: setup1 ? setup1RangeObj.brakeTorque : 0,
              setup2: setup2 ? setup2RangeObj.brakeTorque : 0,
            },
            {
              key: "brakeBias",
              name: "brake bias (%)",
              setup1: setup1 ? setup1RangeObj.brakeBias : 0,
              setup2: setup2 ? setup2RangeObj.brakeBias : 0,
            },
            {
              key: "steerRatio",
              name: "steer ratio (%)",
              setup1: setup1 ? setup1RangeObj.steerRatio : 0,
              setup2: setup2 ? setup2RangeObj.steerRatio : 0,
            },
            {
              key: "wheelRate",
              name: "wheel rate (Nm)",
              setup1: setup1 ? setup1RangeObj.wheelRate : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.wheelRate : [0, 0, 0, 0],
            },
            {
              key: "bumpStopRateUp",
              name: "bumpstop rate (N)",
              setup1: setup1 ? setup1RangeObj.bumpStopRateUp : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.bumpStopRateUp : [0, 0, 0, 0],
            },
            {
              key: "bumpStopWindow",
              name: "bumpstop range",
              setup1: setup1 ? setup1RangeObj.bumpStopWindow : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.bumpStopWindow : [0, 0, 0, 0],
            },
            {
              key: "preload",
              name: "preload diff (Nm)",
              setup1: setup1 ? setup1RangeObj.preload : 0,
              setup2: setup2 ? setup2RangeObj.preload : 0,
            },
          ],
        },
        {
          key: "dampers",
          name: "Dampers",
          setup1: "-",
          setup2: "-",
          children: [
            {
              key: "bumpSlow",
              name: "bump",
              setup1: setup1 ? setup1RangeObj.bumpSlow : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.bumpSlow : [0, 0, 0, 0],
            },
            {
              key: "bumpFast",
              name: "fast bump",
              setup1: setup1 ? setup1RangeObj.bumpFast : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.bumpFast : [0, 0, 0, 0],
            },
            {
              key: "reboundSlow",
              name: "rebound",
              setup1: setup1 ? setup1RangeObj.reboundSlow : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.reboundSlow : [0, 0, 0, 0],
            },
            {
              key: "reboundFast",
              name: "fast rebound",
              setup1: setup1 ? setup1RangeObj.reboundFast : [0, 0, 0, 0],
              setup2: setup2 ? setup2RangeObj.reboundFast : [0, 0, 0, 0],
            },
          ],
        },
        {
          key: "aeroBalance",
          name: "Aero",
          setup1: "-",
          setup2: "-",
          children: [
            {
              key: "rideHeight",
              name: "ride height",
              setup1: setup1
                ? [
                    setup1RangeObj.rideHeightFront,
                    setup1RangeObj.rideHeightRear,
                  ]
                : [0, 0],
              setup2: setup2
                ? [
                    setup2RangeObj.rideHeightFront,
                    setup2RangeObj.rideHeightRear,
                  ]
                : [0, 0],
            },
            {
              key: "rearWing",
              name: "rear wing",
              setup1: setup1 ? setup1RangeObj.rearWing : 0,
              setup2: setup2 ? setup2RangeObj.rearWing : 0,
            },
            {
              key: "splitter",
              name: "splitter",
              setup1: setup1 ? setup1RangeObj.splitter : 0,
              setup2: setup2 ? setup2RangeObj.splitter : 0,
            },
            {
              key: "brakeDuct",
              name: "brake ducts",
              setup1: setup1 ? setup1RangeObj.brakeDuct : [0, 0],
              setup2: setup2 ? setup2RangeObj.brakeDuct : [0, 0],
            },
          ],
        },
      ];
    } else {
      dynamicData = initialData;
    }
    setDynamicData(dynamicData);
  }, [setup1, setup2]);

  const handleSetupOneChange = (info) => {
    setSetup1File(info.fileList[0]);
  };

  const handleSetupTwoChange = (info) => {
    setSetup2File(info.fileList[0]);
  };

  const handleSetupOneRemove = () => {
    setSetup1(false);
    setSetup1File(null);
  };

  const handleSetupTwoRemove = () => {
    setSetup2(false);
    setSetup2File(null);
  };

  const handleShowSampleDiff = () => {
    setMode("compare");

    const setup1Data = require(`../../utils/sample/setups/aggressive.json`);
    const setup2Data = require(`../../utils/sample/setups/wet.json`);

    const file1 = [
      {
        uid: "111111",
        type: "sample",
        name: "agg_nurb_porsche_991ii_gt3_r.json",
      },
    ];

    const file2 = [
      {
        uid: "222222",
        type: "sample",
        name: "wet_nurb_porsche_991ii_gt3_r.json",
      },
    ];

    setSetup1File(file1[0]);
    setSetup2File(file2[0]);

    setCarName(setup1Data.carName);
    setSetup1(setup1Data);
    setSetup2(setup2Data);
  };

  const handleClearSampleDiff = () => {
    navigate("/");
    setCarName(false);
    handleSetupOneRemove();
    handleSetupTwoRemove();
    message.success("Setups cleared successfully.");
  };

  const handleSwapSetups = () => {
    if (setup1 && setup2 && setup1File && setup2File) {
      const setup1Temp = setup1;
      const setup2Temp = setup2;

      const setup1FileTemp = setup1File;
      const setup2FileTemp = setup2File;

      setSetup1(setup2Temp);
      setSetup2(setup1Temp);

      setSetup1File(setup2FileTemp);
      setSetup2File(setup1FileTemp);

      message.success("Setups swapped successfully.");
    }
  };

  const handleModeChange = (mode) => {
    setMode(mode);
    if (mode === "single") {
      handleSetupTwoRemove();
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} xl={24}>
          <SetupsUploadSection
            mode={mode}
            setup1File={setup1File}
            setup2File={setup2File}
            clearSampleDiff={handleClearSampleDiff}
            showSampleDiff={handleShowSampleDiff}
            eventHandlers={{
              onModeChange: handleModeChange,
              onSetupOneChange: handleSetupOneChange,
              onSetupTwoChange: handleSetupTwoChange,
              onSetupOneRemove: handleSetupOneRemove,
              onSetupTwoRemove: handleSetupTwoRemove,
              onSwapSetups: handleSwapSetups,
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col xs={24} sm={24} xl={24}>
          <SupportedCarsSection
            carName={carName}
            style={{ margin: "8px 16px" }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 16, marginBottom: 16 }}>
        <Col xs={24} sm={24} xl={24}>
          <ComparisonSection
            mode={mode}
            style={{ padding: "0 24px" }}
            dynamicData={dynamicData}
            setup2={setup2 ? true : false}
          />
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
