import React from "react";
import { Table, Card } from "antd";
import Chasisicon from "../../../assets/Chasisicon";

const Mechanical = ({ data, mode, isSetup2Loaded }) => {
  // 1. Front data
  const frontKeys = ["aRB", "brakeTorque", "brakeBias", "steerRatio"];
  const frontDataSource = data.children.filter((item) =>
    frontKeys.includes(item.key)
  );

  const frontColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 170,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 100,
      render: (value) => {
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[0];
        } else {
          finalValue = value;
        }
        return finalValue;
      },
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    frontColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 100,
      render: (value, record) => {
        const { setup1, setup2 } = record;
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[0];
        } else {
          finalValue = value;
        }

        let diff;
        if (Array.isArray(setup1) && Array.isArray(setup2)) {
          diff = setup2[0] - setup1[0];
        } else {
          diff = setup2 - setup1;
        }

        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{finalValue}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  // 2. Rear data

  const rearKeys = ["aRB", "preload"];

  const rearDataSource = data.children.filter((item) =>
    rearKeys.includes(item.key)
  );
  const rearColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 170,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 100,
      align: "center",
      render: (value) => {
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[1];
        } else {
          finalValue = value;
        }
        return finalValue;
      },
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    rearColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 100,
      align: "center",
      render: (value, record) => {
        const { setup1, setup2 } = record;
        let finalValue;
        if (Array.isArray(value)) {
          finalValue = value[1];
        } else {
          finalValue = value;
        }

        let diff;
        if (Array.isArray(setup1) && Array.isArray(setup2)) {
          diff = setup2[1] - setup1[1];
        } else {
          diff = setup2 - setup1;
        }

        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{finalValue}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  // 3. Remaining Data
  const remainingKeys = ["wheelRate", "bumpStopRateUp", "bumpStopWindow"];
  const remainingData = data.children.filter((item) =>
    remainingKeys.includes(item.key)
  );

  let leftFrontDataSource = [];
  let leftRearDataSource = [];
  let rightFrontDataSource = [];
  let rightRearDatSource = [];

  remainingData.forEach((item) => {
    const { name, setup1 = [], setup2 = [] } = item;
    if (Array.isArray(setup1)) {
      setup1.forEach((_, index) => {
        if (index === 0) {
          leftFrontDataSource.push({
            name,
            setup1: setup1[index],
            setup2: setup2[index],
          });
        } else if (index === 1) {
          rightFrontDataSource.push({
            name,
            setup1: setup1[index],
            setup2: setup2[index],
          });
        } else if (index === 2) {
          leftRearDataSource.push({
            name,
            setup1: setup1[index],
            setup2: setup2[index],
          });
        } else if (index === 3) {
          rightRearDatSource.push({
            name,
            setup1: setup1[index],
            setup2: setup2[index],
          });
        } else {
          console.log("invalid index");
        }
      });
    }
  });

  const remainingDataColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 170,
    },
    {
      title: "Setup1",
      dataIndex: "setup1",
      key: "setup1",
      width: 150,
      align: "center",
    },
  ];

  if (mode === "compare" && isSetup2Loaded) {
    remainingDataColumns.push({
      title: "Setup2",
      dataIndex: "setup2",
      key: "setup2",
      width: 150,
      align: "center",
      render: (val, record) => {
        const { setup1, setup2 } = record;
        const diff = setup2 - setup1;
        const diffRounded = Math.round((diff + Number.EPSILON) * 100) / 100;
        let element;
        if (diffRounded > 0) {
          element = <span style={{ color: "green" }}>{"+" + diffRounded}</span>;
        } else if (diffRounded < 0) {
          element = <span style={{ color: "brown" }}>{diffRounded}</span>;
        } else {
          element = null;
        }
        return (
          <div className="value__delta">
            <span className="value">{val}</span>
            {element && <span className="delta">({element})</span>}
          </div>
        );
      },
    });
  }

  return (
    <Card type="inner" title="MECHANICAL GRIP" style={{ marginTop: 8 }}>
      <div
        className="setup-section"
        style={{
          display: "flex",
          background: "white",
          justifyContent: "center",
          padding: 48,
        }}
      >
        <div
          className="res-no-margin"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: 64,
          }}
        >
          <div class="item">
            <Card type="inner" title="Left Front" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={leftFrontDataSource}
                columns={remainingDataColumns}
              />
            </Card>
          </div>
          <div className="item res-no-margin" style={{ marginTop: 64 }}>
            <Card type="inner" title="Left Rear" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={leftRearDataSource}
                columns={remainingDataColumns}
              />
            </Card>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div class="item">
            <Card type="inner" title="Front" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={frontDataSource}
                columns={frontColumns}
              />
            </Card>
          </div>
          <div class="item res-no-margin" style={{ margin: "64px 0 " }}>
            <Chasisicon
              className="setup-chasis-icon"
              style={{ verticalAlign: "middle", fill: "black" }}
              width={350}
              height={124}
            />
          </div>
          <div class="item">
            <Card type="inner" title="Rear" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={rearDataSource}
                columns={rearColumns}
              />
            </Card>
          </div>
        </div>

        <div
          className="res-no-margin"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: 64,
          }}
        >
          <div className="item">
            <Card type="inner" title="Right Front" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={rightFrontDataSource}
                columns={remainingDataColumns}
              />
            </Card>
          </div>
          <div className="item res-no-margin" style={{ marginTop: 64 }}>
            <Card type="inner" title="Right Rear" bodyStyle={{ padding: 0 }}>
              <Table
                showHeader={false}
                className="setup-data-table"
                bordered
                pagination={{
                  hideOnSinglePage: true,
                }}
                dataSource={rightRearDatSource}
                columns={remainingDataColumns}
              />
            </Card>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Mechanical;
